import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form/Input';
import { useNavigate, useParams } from 'react-router-dom';
import {
    append,
    save,
} from '../../../core/services/firebase/resources.service';
import { Row } from '../../shared/Row';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK } from '../../../core/constants/routes';
import { Col } from '../../shared/Col';
import { SaveButton } from '../../shared/button/SaveButton';
import { MenuResponse } from 'core/models/Menu';
import { Checkbox } from 'components/shared/form/Checkbox';
import { Button } from 'components/shared/button/Button';
import { Badge } from 'components/shared/Badge';

type Props = {
    menu?: MenuResponse;
};

export const MenuDetailForm = ({ menu }: Props) => {
    const T_RESOURCE = 'pages.back.menus.detail';

    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const methods = useForm({
        defaultValues: menu,
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data: MenuResponse) => {
        if (menu) {
            save(RESOURCES.MENU, id, data);
        } else {
            append(RESOURCES.MENU, data);
        }
        navigate(BACK.MENU);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButton floating submit />
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <Input
                                name={`title.${language}`}
                                label={t(`${T_RESOURCE}.fields.title`, {
                                    language,
                                })}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="url"
                            label={t(`${T_RESOURCE}.fields.url`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            type="number"
                            name="position"
                            label={t(`${T_RESOURCE}.fields.position`)}
                            required
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Checkbox
                            name="visible"
                            label={t(`${T_RESOURCE}.fields.visible`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1}>
                        <Button
                            onClick={() => id && navigate(BACK.SUB_MENU(id))}
                        >
                            {t(`${T_RESOURCE}.subItems`)}
                            <Badge color="light" className="ms-2">
                                {menu?.subItems?.length}
                            </Badge>
                        </Button>
                    </Col>
                </Row>
            </form>
        </FormProvider>
    );
};
