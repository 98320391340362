import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconBootstrap } from '../Icon';
import { FormLabel } from './FormLabel';
import clsx from 'clsx';
import { MarginType, PaddingType } from '../type/BootstrapType';
import { ErrorLabel } from './ErrorLabel';

const TAGS = {
    STRONG: { name: 'strong', autoClose: false },
    I: { name: 'i', autoClose: false },
    U: { name: 'u', autoClose: false },
    S: { name: 's', autoClose: false },
    A: { name: 'a', autoClose: false },
    H1: { name: 'h1', autoClose: false },
    H2: { name: 'h2', autoClose: false },
    H3: { name: 'h3', autoClose: false },
    H4: { name: 'h4', autoClose: false },
    H5: { name: 'h5', autoClose: false },
    H6: { name: 'h6', autoClose: false },
    BREAK: { name: 'br', autoClose: true },
    TEXT_LEFT: { name: 'p', autoClose: false },
    TEXT_CENTER: { name: 'p', autoClose: false },
    TEXT_RIGHT: { name: 'p', autoClose: false },
    TEXT_JUSTIFY: { name: 'p', autoClose: false },
};

type Props = {
    label?: string | null;
    placeholder?: string | null;
    name: string;
    required?: boolean;
    disabled?: boolean;
    rows?: number;
    richText?: boolean;
    className?: string;
    m?: MarginType;
    p?: PaddingType;
};

export const TextArea = ({
    label,
    name,
    required = false,
    placeholder,
    disabled = false,
    rows = 4,
    richText = false,
    className,
    m = 0,
    p = 0,
}: Props) => {
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
        getValues,
        setValue,
    } = useFormContext();

    const requiredObject = {
        required: t('validation.required'),
    };

    const { ref, ...registration } = register(name, {
        ...(required ? requiredObject : {}),
    });

    const hasError = !!errors[name];

    const checkTags = (value: string) =>
        Object.values(TAGS)
            .filter(({ autoClose }) => autoClose)
            .every(({ name: tag }) => {
                const startTag = `<${tag}`;
                const endTag = `</${tag}>`;
                return (
                    (value.includes(startTag) && value.includes(endTag)) ||
                    (!value.includes(startTag) && !value.includes(endTag))
                );
            });

    const handleRichButtonClick =
        (tag: (typeof TAGS)[keyof typeof TAGS], attrs?: string) => () => {
            const textarea = textAreaRef.current;
            if (textarea) {
                const startPos = textarea.selectionStart || 0;
                const endPos = textarea.selectionEnd || 0;
                if (startPos !== endPos && !tag.autoClose) {
                    const oldValue = getValues(name);
                    const selectedText = oldValue.substring(startPos, endPos);
                    if (checkTags(selectedText)) {
                        const newValue =
                            oldValue.substring(0, startPos) +
                            `<${tag.name}${
                                attrs ? ` ${attrs}` : ''
                            }>${selectedText}</${tag.name}>` +
                            oldValue.substring(endPos);
                        setValue(name, newValue);
                    }
                } else if (startPos === endPos && tag.autoClose) {
                    const oldValue = getValues(name);
                    const newValue =
                        oldValue.substring(0, startPos) +
                        `<${tag.name}${attrs ? ` ${attrs}` : ''} />` +
                        oldValue.substring(endPos);
                    setValue(name, newValue);
                }
            }
        };

    const iconProps = { className: 'mx-1', disabled };

    return (
        <div className={clsx('text-start', className, `m-${m}`, `p-${p}`)}>
            {label && <FormLabel label={label} />}
            {richText && (
                <div>
                    <IconBootstrap
                        {...iconProps}
                        name="type-bold"
                        onClick={handleRichButtonClick(TAGS.STRONG)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-italic"
                        onClick={handleRichButtonClick(TAGS.I)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-underline"
                        onClick={handleRichButtonClick(TAGS.U)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-strikethrough"
                        onClick={handleRichButtonClick(TAGS.S)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="link-45deg"
                        onClick={handleRichButtonClick(TAGS.A, 'href=""')}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-h1"
                        onClick={handleRichButtonClick(TAGS.H1)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-h2"
                        onClick={handleRichButtonClick(TAGS.H2)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-h3"
                        onClick={handleRichButtonClick(TAGS.H3)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-h4"
                        onClick={handleRichButtonClick(TAGS.H4)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-h5"
                        onClick={handleRichButtonClick(TAGS.H5)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="type-h6"
                        onClick={handleRichButtonClick(TAGS.H6)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="arrow-return-left"
                        onClick={handleRichButtonClick(TAGS.BREAK)}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="text-left"
                        onClick={handleRichButtonClick(
                            TAGS.TEXT_LEFT,
                            'style="text-align: start;"'
                        )}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="text-center"
                        onClick={handleRichButtonClick(
                            TAGS.TEXT_CENTER,
                            'style="text-align: center;"'
                        )}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="text-right"
                        onClick={handleRichButtonClick(
                            TAGS.TEXT_RIGHT,
                            'style="text-align: end;"'
                        )}
                    />
                    <IconBootstrap
                        {...iconProps}
                        name="justify"
                        onClick={handleRichButtonClick(
                            TAGS.TEXT_JUSTIFY,
                            'style="text-align: justify;"'
                        )}
                    />
                </div>
            )}
            <textarea
                {...registration}
                className={clsx('form-control', {
                    'border border-danger': hasError,
                })}
                placeholder={placeholder ?? label ?? ''}
                disabled={disabled}
                rows={rows}
                ref={(e) => {
                    ref(e);
                    textAreaRef.current = e as HTMLTextAreaElement;
                }}
            ></textarea>
            {name && <ErrorLabel name={name} />}
        </div>
    );
};
