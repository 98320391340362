import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form/Input';
import { useNavigate, useParams } from 'react-router-dom';
import {
    append,
    save,
} from '../../../core/services/firebase/resources.service';
import { TypeResponse } from '../../../core/models/Type';
import { Row } from '../../shared/Row';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK } from '../../../core/constants/routes';
import { Col } from '../../shared/Col';
import { SaveButton } from '../../shared/button/SaveButton';

type Props = {
    type?: TypeResponse;
};

export const TypeDetailForm = ({ type }: Props) => {
    const T_RESOURCE = 'pages.back.types.detail';

    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const methods = useForm({
        defaultValues: type,
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data: TypeResponse) => {
        if (type) {
            save(RESOURCES.TYPES, id, data);
        } else {
            append(RESOURCES.TYPES, data);
        }
        navigate(BACK.TYPES);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButton floating submit />
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="code"
                            label={t(`${T_RESOURCE}.fields.code`)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <Input
                                name={`text.${language}`}
                                label={t(`${T_RESOURCE}.fields.text`, {
                                    language,
                                })}
                            />
                        </Col>
                    ))}
                </Row>
            </form>
        </FormProvider>
    );
};
