export const TEAM_ROLES = [
    'directedBy',
    'originalIdea',
    'screenWriter',
    'cast',
    'production',
    'photographyDirector',
    'artDirector',
    'sound',
    'costumeDesigner',
    'makeUp',
    'edition',
    'color',
    'music',
    'distribution',
    'graphicDesign',
    'vfx',
    'author',
    'illustrator',
    'editor',
];
