import React, { ReactNode } from 'react';
import { Award } from 'core/models/Award';
import { Row } from 'components/shared/Row';
import { Col } from 'components/shared/Col';
import { ProjectAwardsV2 } from './ProjectAwardsV2';

type Props = {
    children: ReactNode;
    awards: Award[];
};

export const ProjectColumn = ({ children, awards }: Props) => {
    const hasAwards = awards.length > 0;

    return (
        <Row>
            <Col
                px={1}
                className={`col-12 col-md-${hasAwards ? '10' : '12'} ps-0`}
            >
                {children}
            </Col>
            {hasAwards && <ProjectAwardsV2 awards={awards} />}
        </Row>
    );
};
