import React from 'react';
import { IconBootstrap } from '../../shared/Icon';
import { Badge } from '../../shared/Badge';
import { useTranslation } from 'react-i18next';
import { TranslatedString } from '../../../core/models/TranslatedString';
import { DeleteButton } from '../../shared/button/DeleteButton';
import { Card } from '../../shared/Card';
import { MenuResponse } from 'core/models/Menu';

type Props = {
    menu: MenuResponse;
    onClick: () => void;
    onDelete: () => void;
};

export const MenuCard = ({
    menu: { title, position, visible, subItems, url },
    onClick,
    onDelete,
}: Props) => {
    const { i18n } = useTranslation();

    return (
        <Card
            onClick={onClick}
            actions={
                <>
                    <Badge color="secondary">{url}</Badge>
                    {subItems?.length > 0 && (
                        <Badge className="ms-2 d-flex align-items-center">
                            <>
                                <IconBootstrap
                                    name="list-ul"
                                    color="white"
                                    className="me-2"
                                    size="xs"
                                />
                                {subItems?.length}
                            </>
                        </Badge>
                    )}
                    {position && <Badge className="ms-2">{position}</Badge>}
                    {onDelete && <DeleteButton onClick={onDelete} />}
                </>
            }
            m={2}
            p={2}
        >
            <IconBootstrap
                name={visible ? 'eye' : 'eye-slash'}
                className="me-2"
            />
            <div className="d-flex flex-column">
                {i18n.languages.map((language) => (
                    <span key={language}>
                        <Badge className="mx-1">{language}</Badge>
                        {title?.[language as keyof TranslatedString]}
                    </span>
                ))}
            </div>
        </Card>
    );
};
