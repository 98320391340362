import React from 'react';
import { Language } from '../../../core/models/Language';
import { IconBootstrap } from '../../shared/Icon';
import { H5 } from '../../shared/H';
import { Badge } from '../../shared/Badge';
import { Card } from '../../shared/Card';

type Props = {
    language: Language;
    onClick: () => void;
};

export const LanguageCard = ({
    language: { code, name, visible, position },
    onClick,
}: Props) => {
    return (
        <Card
            onClick={onClick}
            actions={<>{position && <Badge>{position}</Badge>}</>}
            m={2}
            p={2}
        >
            <IconBootstrap
                name={visible ? 'eye' : 'eye-slash'}
                className="me-1"
            />
            <H5>
                {code} - {name}
            </H5>
        </Card>
    );
};
