import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { User } from 'firebase/auth';

type Props = {
    user: User | null | undefined;
    defaultRoute: string;
    children: ReactNode;
};

export const ProtectedRoute = ({ user, defaultRoute, children }: Props) => {
    if (!user) {
        return <Navigate to={defaultRoute} />;
    }
    return <>{children}</>;
};
