import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { RESOURCES } from '../../../core/constants/resources';
import { MenuSubItemResponse } from 'core/models/Menu';
import { SubMenuDetailForm } from 'components/back/form/SubMenuDetailForm';

export const SubMenuDetail = () => {
    const { id: menuId, subItemId } = useParams();

    const { data: subItem, loading } = useGetOne<
        MenuSubItemResponse,
        MenuSubItemResponse
    >({ resource: `${RESOURCES.MENU}/${menuId}/subItems`, id: subItemId });

    if (loading || !subItem) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <SubMenuDetailForm subItem={subItem} />
        </BackLayout>
    );
};
