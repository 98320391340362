import React from 'react';
import clsx from 'clsx';
import { MarginType, PaddingType } from '../type/BootstrapType';

type Props = {
    label: string;
    className?: string;
    htmlFor?: string;
    m?: MarginType;
    p?: PaddingType;
};

export const FormLabel = ({
    label,
    className,
    htmlFor,
    m = 0,
    p = 0,
}: Props) => {
    return (
        <label
            className={clsx('form-label', className, `m-${m}`, `p-${p}`, {
                pointer: htmlFor,
            })}
            htmlFor={htmlFor}
        >
            {label}
        </label>
    );
};
