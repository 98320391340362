import React from 'react';
import clsx from 'clsx';
import { ColorTypeBig } from './type/BootstrapType';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@tabler/icons-webfont/dist/tabler-icons.css';

export const BI_TYPE = 'bi';
export const TI_TYPE = 'ti';

type Props = {
    name: string;
    prefix: string;
    color?: ColorTypeBig;
    className?: string;
    disabled?: boolean;
    hasNotification?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    onClick?: () => void;
};

export const Icon = ({
    name,
    prefix,
    color = 'primary',
    className,
    disabled = false,
    hasNotification = false,
    size = 'sm',
    onClick,
}: Props) => {
    let sizeClassName = 'fs-';
    if (size === 'xs') {
        sizeClassName += '6';
    } else if (size === 'sm') {
        sizeClassName += '5';
    } else if (size === 'md') {
        sizeClassName += '4';
    } else if (size === 'lg') {
        sizeClassName += '3';
    } else if (size === 'xl') {
        sizeClassName += '2';
    } else if (size === 'xxl') {
        sizeClassName += '1';
    }

    return (
        <i
            className={clsx(
                'position-relative',
                prefix,
                className,
                sizeClassName,
                {
                    [`${prefix}-${name}`]: name,
                    'text-black-50': color && disabled,
                    [`text-${color}`]: color && !disabled,
                    'opacity-50': disabled,
                }
            )}
            role={disabled || !onClick ? 'none' : 'button'}
            onClick={onClick}
        >
            {hasNotification && (
                <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"></span>
            )}
        </i>
    );
};

// https://icons.getbootstrap.com/

export const IconBootstrap = (props: Omit<Props, 'prefix'>) => {
    return <Icon {...props} prefix={BI_TYPE} />;
};

// https://tabler.io/icons

export const IconTabler = (props: Omit<Props, 'prefix'>) => {
    return <Icon {...props} prefix={TI_TYPE} />;
};