import React from 'react';
import { OtherWork } from '../../core/models/OtherWork';
import { P } from '../shared/P';
import { useTranslation } from 'react-i18next';
import { IconBootstrap } from 'components/shared/Icon';

type Props = {
    otherWork: OtherWork;
};

export const OtherWorkCard = ({
    otherWork: { title, link, description, year, rol, direction, productor },
}: Props) => {
    const { t } = useTranslation();

    const secondP = [
        ...(description ? [description] : []),
        ...(direction
            ? [`${t('pages.project.fields.directedBy')} ${direction}`]
            : []),
        ...(year ? [year] : []),
    ].join(', ');

    return (
        <div className="my-4">
            <div className="d-flex">
                {link ? (
                    <a href={link} target="_blank" rel="noreferrer">
                        {title}
                    </a>
                ) : (
                    <>{title}</>
                )}
                {rol && (
                    <P className="ms-1">
                        (<b>{rol}</b>)
                    </P>
                )}
            </div>
            <P>{secondP}</P>
            <P>
                {productor?.name ? `${productor?.name} ` : ''}
                {productor?.link && (
                    <IconBootstrap
                        name="box-arrow-up-right"
                        size="xs"
                        onClick={() => window.open(productor?.link, '_blank')}
                    />
                )}
            </P>
        </div>
    );
};
