import React from 'react';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { RESOURCES } from 'core/constants/resources';

export const Footer = () => {
    const { data: footer, loading } = useGetOne<string, string>({
        resource: RESOURCES.GENERAL,
        id: 'footer',
    });

    if (loading) {
        return <></>;
    }

    return (
        <footer className="mt-auto">
            <div
                className="text-center p-3"
                style={{ fontSize: '11px' }}
                dangerouslySetInnerHTML={{ __html: footer ?? '' }}
            ></div>
        </footer>
    );
};
