import React, { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import { MarginType, PaddingType } from './type/BootstrapType';

type Props = {
    children?: ReactNode;
    className?: string;
    m?: MarginType;
    p?: PaddingType;
    px?: PaddingType;
    py?: PaddingType;
    style?: CSSProperties;
};

export const Col = ({
    children,
    className,
    m = 0,
    p = 0,
    px = 0,
    py = 0,
    style,
}: Props) => {
    return (
        <div
            className={clsx(
                'col',
                `p-${p}`,
                `m-${m}`,
                `px-${px}`,
                `py-${py}`,
                className
            )}
            style={style}
        >
            {children}
        </div>
    );
};
