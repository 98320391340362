import React, { ReactNode } from 'react';
import clsx from 'clsx';

type RootProps = {
    children: ReactNode;
};

export const Root = ({ children }: RootProps) => {
    return (
        <nav>
            <ol className="breadcrumb mb-0">{children}</ol>
        </nav>
    );
};

type ItemProps = {
    title: string;
    url?: string;
    active?: boolean;
};

export const Item = ({ title, url, active = false }: ItemProps) => {
    return (
        <li className={clsx('breadcrumb-item', { active: active })}>
            {url && <a href={url}>{title}</a>}
            {!url && <>{title}</>}
        </li>
    );
};

export const Breadcrumb = { Root, Item };
