import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Project, ProjectResponse } from '../core/models/Project';
import { MainLayout } from '../components/layouts/main/MainLayout';
import { useTranslation } from 'react-i18next';
import { projectMapper } from '../core/mapper/projectMapper';
import { Spinner } from '../components/shared/Spinner';
import { useGetOneByHash } from '../core/hooks/firebase/useGetOneByHash';
import { ProjectDetailFilm } from '../components/projects/ProjectDetailFilm';
import { useGetAll } from '../core/hooks/firebase/useGetAll';
import { Type, TypeResponse } from '../core/models/Type';
import { typeMapper } from '../core/mapper/typeMapper';
import { subTypeMapper } from '../core/mapper/subTypeMapper';
import { SubType, SubTypeResponse } from '../core/models/SubType';
import { SubSubType, SubSubTypeResponse } from '../core/models/SubSubType';
import { subSubTypeMapper } from '../core/mapper/subSubTypeMapper';
import { RESOURCES } from '../core/constants/resources';
import { ProjectDetailPhoto } from '../components/projects/ProjectDetailPhoto';
import { ProjectDetailWriting } from '../components/projects/ProjectDetailWriting';
import { Award, AwardResponse } from 'core/models/Award';
import { awardMapper } from 'core/mapper/awardMapper';

export const ProjectDetail = () => {
    const { i18n } = useTranslation();
    const { id: projectHash } = useParams();

    const { data: types, mutation: typesMutation } = useGetAll<
        Type,
        TypeResponse
    >({ resource: RESOURCES.TYPES, mapper: typeMapper(i18n.language) });

    const { data: subTypes, mutation: subTypesMutation } = useGetAll<
        SubType,
        SubTypeResponse
    >({ resource: RESOURCES.SUBTYPES, mapper: subTypeMapper(i18n.language) });

    const { data: subSubTypes, mutation: subSubTypesMutation } = useGetAll<
        SubSubType,
        SubSubTypeResponse
    >({
        resource: RESOURCES.SUBSUBTYPES,
        mapper: subSubTypeMapper(i18n.language),
    });

    const { data: awards } = useGetAll<Award, AwardResponse>({
        resource: RESOURCES.AWARDS,
        mapper: awardMapper,
    });

    const { data: project, mutation: projectsMutation } = useGetOneByHash<
        Project,
        ProjectResponse
    >({
        resource: RESOURCES.PROJECTS,
        hash: projectHash,
        mapper: projectMapper(i18n.language)(
            types,
            subTypes,
            subSubTypes,
            awards
        ),
    });

    useEffect(() => {
        typesMutation();
        subSubTypesMutation();
        subTypesMutation();
    }, [i18n.language]);

    useEffect(() => {
        projectsMutation();
    }, [types, subTypes, subSubTypes, awards]);

    if (!project) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const TITLE_SIZE = 28;
    const CONTENT_SIZE = 14;

    return (
        <MainLayout>
            {project.type?.code === 'film' && (
                <ProjectDetailFilm
                    project={project}
                    titleSize={TITLE_SIZE}
                    contentSize={CONTENT_SIZE}
                />
            )}
            {project.type?.code === 'photo' && (
                <ProjectDetailPhoto
                    project={project}
                    titleSize={TITLE_SIZE}
                    contentSize={CONTENT_SIZE}
                />
            )}
            {project.type?.code === 'writing' && (
                <ProjectDetailWriting
                    project={project}
                    titleSize={TITLE_SIZE}
                    contentSize={CONTENT_SIZE}
                />
            )}
        </MainLayout>
    );
};
