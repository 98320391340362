export const RESOURCES = {
    ROOT: '',
    GENERAL: 'general',
    PROJECTS: 'proyects',
    CATEGORIES: 'categories',
    LANGUAGES: 'languages',
    TYPES: 'types',
    SUBTYPES: 'subTypes',
    SUBSUBTYPES: 'subSubTypes',
    MENU: 'menu',
    OTHER_WORKS: 'otherWorks',
    AWARDS: 'awards',
};
