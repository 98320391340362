import React from 'react';
import { LanguageSelector } from '../../shared/LanguageSelector';
import { ROOT } from '../../../core/constants/routes';
import { ResponsiveMenu } from './ResponsiveMenu';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { useGetAll } from 'core/hooks/firebase/useGetAll';
import { SortDirection } from 'core/models/shared/SortDirection';
import { RESOURCES } from 'core/constants/resources';
import { Language } from 'core/models/Language';
import { setLanguage } from 'i18n';
import clsx from 'clsx';
import ProjectSearchInput from 'components/projects/ProjectSearchInput';

type Props = {
    breakPoint: 'sm' | 'md' | 'lg' | 'xl';
    height: string;
    isTopFixed?: boolean;
    displaySearchInput?: boolean;
};

export const Header = ({
    breakPoint,
    height,
    isTopFixed = true,
    displaySearchInput = false,
}: Props) => {
    const { data: logo, loading: loadingLogo } = useGetOne<string, string>({
        resource: RESOURCES.GENERAL,
        id: 'logo',
    });

    const { data: description, loading: loadingDescription } = useGetOne<
        string,
        string
    >({
        resource: RESOURCES.GENERAL,
        id: 'description',
    });

    const { data: languages } = useGetAll<Language, Language>({
        resource: RESOURCES.LANGUAGES,
        mapper: (obj) => obj,
        filters: { visible: true },
        sort: { key: 'position', direction: SortDirection.ASC },
    });

    if (loadingLogo || loadingDescription) {
        return <></>;
    }

    return (
        <header
            className={clsx(
                'bg-white d-flex justify-content-between align-items-center',
                { 'fixed-top': isTopFixed }
            )}
            style={{ height: height }}
        >
            <div className="container d-flex justify-content-between align-items-center">
                <div>
                    <a href={ROOT} className="p-1">
                        {logo && (
                            <img
                                src={logo}
                                className="img-fluid"
                                alt="logo"
                                style={{ maxWidth: '300px', maxHeight: height }}
                            />
                        )}
                    </a>
                    <span className="ms-2 d-none d-md-inline">
                        {description}
                    </span>
                </div>
                <div className={clsx('d-none', `d-${breakPoint}-flex`)}>
                    {displaySearchInput && (
                        <ProjectSearchInput />
                    )}
                    <LanguageSelector
                        languages={languages}
                        className="d-flex justify-content-end align-items-center"
                        onChange={(code: string) => setLanguage(code)}
                        optionStyles={{ fontSize: '11px' }}
                    />
                </div>
                <div className={clsx('d-block', `d-${breakPoint}-none`)}>
                    <ResponsiveMenu
                        height={height}
                        breakPoint={breakPoint}
                        displaySearchInput={displaySearchInput}
                    />
                </div>
            </div>
        </header>
    );
};
