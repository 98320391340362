import React from 'react';
import ReactPlayer from 'react-player';
import { Spinner } from '../Spinner';

// https://github.com/CookPete/react-player#props

type Props = {
    url: string;
};

export const VideoPlayer = ({ url }: Props) => {
    return (
        <div
            style={{
                position: 'relative',
                paddingTop: '56.25%',
            }}
        >
            <ReactPlayer
                url={url}
                controls={true}
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0 }}
                fallback={<Spinner />}
            />
        </div>
    );
};
