import React from 'react';
import { MainLayout } from '../components/layouts/main/MainLayout';
import { useGetOne } from '../core/hooks/firebase/useGetOne';
import {
    Contact as ContactModel,
    ContactResponse,
} from '../core/models/Contact';
import { Row } from '../components/shared/Row';
import { RESOURCES } from '../core/constants/resources';
import { contactMapper } from '../core/mapper/contactMapper';
import { Spinner } from '../components/shared/Spinner';
import { P } from '../components/shared/P';
import { Col } from '../components/shared/Col';

export const Contact = () => {
    const { data: contact, loading } = useGetOne<ContactModel, ContactResponse>(
        { resource: RESOURCES.ROOT, id: 'contact', mapper: contactMapper }
    );

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <MainLayout>
            <Row className="d-flex justify-content-start">
                <Col className="col-12 col-md-3">
                    <img src={contact?.image} className="img-fluid" />
                </Col>
                <Col className="col-12 col-md-6 text-start mt-3">
                    {contact?.name && <P className="mb-1">{contact.name}</P>}
                    {contact?.email && (
                        <P className="mb-1" bold>
                            {contact.email}
                        </P>
                    )}
                    {contact?.phone && (
                        <P className="mb-1" bold>
                            {contact.phone}
                        </P>
                    )}
                    {contact?.instagram && (
                        <a href={contact.instagram} target="=_blank">
                            <img
                                src="/img/logos/instagram.png"
                                style={{ width: '30px', marginRight: '1px' }}
                            />
                        </a>
                    )}
                    {contact?.imdb && (
                        <a href={contact.imdb} target="=_blank">
                            <img
                                src="/img/logos/imdb.png"
                                style={{ width: '70px', marginRight: '3px' }}
                            />
                        </a>
                    )}
                    {contact?.linkedin && (
                        <a href={contact.linkedin} target="=_blank">
                            <img
                                src="/img/logos/linkedin.png"
                                style={{ width: '25px', marginRight: '1px' }}
                            />
                        </a>
                    )}
                </Col>
            </Row>
        </MainLayout>
    );
};
