import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from './Modal';
import { Button } from '../button/Button';

type Props = {
    show: boolean;
    title: string;
    children: ReactNode;
    onDelete: () => void;
    onClose: () => void;
};

export const DeleteModal = ({
    show,
    title,
    children,
    onDelete,
    onClose,
}: Props) => {
    const { t } = useTranslation();

    return (
        <Modal.Root show={show}>
            <Modal.Header title={title} onClose={onClose} />
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t('common.buttons.cancel')}
                </Button>
                <Button onClick={onDelete}>{t('common.buttons.delete')}</Button>
            </Modal.Footer>
        </Modal.Root>
    );
};
