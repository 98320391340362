import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { MarginType, PaddingType } from '../type/BootstrapType';

type Props = {
    children?: ReactNode;
    variant?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark'
        | 'link';
    className?: string;
    outlined?: boolean;
    submit?: boolean;
    size?: 'lg' | 'sm';
    disabled?: boolean;
    onClick?: () => void;
    m?: MarginType;
    p?: PaddingType;
    fullWidth?: boolean;
};

export const Button = ({
    children,
    variant = 'primary',
    className,
    outlined,
    submit = false,
    size,
    disabled = false,
    onClick,
    m,
    p,
    fullWidth = false,
}: Props) => {
    const buttonNode = (
        <button
            type={submit ? 'submit' : 'button'}
            className={clsx(
                'btn',
                'd-flex align-items-center justify-content-center',
                className,
                {
                    [`btn-${variant}`]: variant && !outlined,
                    [`btn-outline-${variant}`]: outlined,
                    [`btn-${size}`]: size,
                    [`m-${m}`]: m != undefined,
                    [`p-${p}`]: p != undefined,
                }
            )}
            disabled={disabled}
            onClick={() => onClick && onClick()}
        >
            {children}
        </button>
    );
    if (!fullWidth) {
        return <>{buttonNode}</>;
    }
    return (
        <div className="d-grid gap-2 col-12 mx-auto text-center">
            {buttonNode}
        </div>
    );
};
