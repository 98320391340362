import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { TypeDetailForm } from '../../../components/back/form/TypeDetailForm';

export const TypeCreate = () => {
    return (
        <BackLayout>
            <TypeDetailForm />
        </BackLayout>
    );
};
