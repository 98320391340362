import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { LanguageCard } from '../../../components/back/card/LanguageCard';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { Language } from '../../../core/models/Language';
import { SortDirection } from '../../../core/models/shared/SortDirection';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK } from '../../../core/constants/routes';
import { useNavigate } from 'react-router-dom';

export const Languages = () => {
    const navigate = useNavigate();

    const { data: languages, loading } = useGetAll<Language, Language>({
        resource: RESOURCES.LANGUAGES,
        mapper: (obj) => obj,
        filters: undefined,
        sort: {
            key: 'position',
            direction: SortDirection.ASC,
        },
    });

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (language: Language) => () => {
        navigate(BACK.LANGUAGE_DETAIL(language.id));
    };

    return (
        <BackLayout>
            {languages.map((language) => (
                <LanguageCard
                    key={language.code}
                    language={language}
                    onClick={handleCardClick(language)}
                />
            ))}
        </BackLayout>
    );
};
