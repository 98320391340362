import React, { ReactNode, isValidElement } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { FormLabel } from './FormLabel';
import { ErrorLabel } from './ErrorLabel';

type LableType = string | ReactNode | null;

type Props = {
    name: string;
    label?: LableType;
    disabled?: boolean;
    labelPosition?: 'start' | 'end' | 'top' | 'bottom';
    doRegister?: boolean;
    checked?: boolean;
    className?: string;
    onChange?: (value: boolean) => void;
};

export const Checkbox = ({
    name,
    label,
    disabled,
    labelPosition = 'top',
    doRegister = true,
    checked,
    className,
    onChange,
}: Props) => {
    const formContext = useFormContext();
    const register = formContext?.register;
    const registration = doRegister ? register(name) : {};

    const id = crypto.randomUUID();

    return (
        <div
            className={clsx('d-flex', className, {
                'flex-column align-items-start':
                    labelPosition === 'top' || labelPosition === 'bottom',
                'flex-row align-items-center':
                    labelPosition === 'start' || labelPosition === 'end',
            })}
        >
            {(labelPosition === 'top' || labelPosition === 'start') && (
                <CheckboxLabel label={label} htmlFor={id} />
            )}
            <input
                id={id}
                {...registration}
                type="checkbox"
                className="form-check-input p-0 m-0 pointer"
                disabled={disabled}
                checked={checked}
                onChange={(e) => onChange?.(e.target.checked)}
            />
            {(labelPosition === 'bottom' || labelPosition === 'end') && (
                <CheckboxLabel label={label} htmlFor={id} />
            )}
            {name && <ErrorLabel name={name} />}
        </div>
    );
};

type CheckboxLabelProps = {
    label: LableType;
    htmlFor?: string;
};

const CheckboxLabel = ({ label, htmlFor }: CheckboxLabelProps) => {
    if (isValidElement(label)) {
        return (
            <label className="pointer" htmlFor={htmlFor}>
                {label}
            </label>
        );
    }
    return <FormLabel label={label as string} htmlFor={htmlFor} />;
};
