import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { SubTypeDetailForm } from '../../../components/back/form/SubTypeDetailForm';

export const SubTypeCreate = () => {
    return (
        <BackLayout>
            <SubTypeDetailForm />
        </BackLayout>
    );
};
