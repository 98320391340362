import { SubSubType, SubSubTypeResponse } from '../models/SubSubType';
import { TranslatedString } from '../models/TranslatedString';

/* prettier-ignore */
export const subSubTypeMapper = (locale: string) => (subSubType: SubSubTypeResponse): SubSubType => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        id: subSubType?.id ?? '',
        code: subSubType?.code ?? '',
        text: subSubType?.text?.[translatedStringLocale] ?? '',
    };
};

export const convertSubSubTypeToSelectOption = (
    subSubType: SubSubType
): { key: string; name: string } => ({ key: subSubType.code, name: subSubType.text });
