import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { OtherWorkResponse } from '../../../core/models/OtherWork';
import { RESOURCES } from '../../../core/constants/resources';
import { OtherWorkDetailForm } from '../../../components/back/form/OtherWorkDetailForm';

export const OtherWorkDetail = () => {
    const { id: otherWorkId } = useParams();

    const { data: otherWork, loading } = useGetOne<
        OtherWorkResponse,
        OtherWorkResponse
    >({ resource: RESOURCES.OTHER_WORKS, id: otherWorkId });

    if (loading || !otherWork) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <OtherWorkDetailForm otherWork={otherWork} />
        </BackLayout>
    );
};
