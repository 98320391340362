import React from 'react';
import { BackLayout } from '../../components/layouts/back/BackLayout';
import { Spinner } from '../../components/shared/Spinner';
import { useGetOne } from '../../core/hooks/firebase/useGetOne';
import { GeneralForm } from '../../components/back/form/GeneralForm';
import { RESOURCES } from '../../core/constants/resources';
import { AboutResponse } from '../../core/models/About';
import { ContactResponse } from '../../core/models/Contact';
import { Meta } from '../../core/models/shared/Meta';

export const General = () => {
    const { data: description, loading: loadingDescription } = useGetOne<
        string,
        string
    >({
        resource: RESOURCES.GENERAL,
        id: 'description',
    });

    const { data: logo, loading: loadingLogo } = useGetOne<string, string>({
        resource: RESOURCES.GENERAL,
        id: 'logo',
    });

    const { data: footer, loading: loadingFooter } = useGetOne<string, string>({
        resource: RESOURCES.GENERAL,
        id: 'footer',
    });

    const { data: fontFamilyPrimary, loading: loadingFontFamilyPrimary } = useGetOne<
        string,
        string
    >({
        resource: RESOURCES.GENERAL,
        id: 'fontFamilyPrimary',
    });

    const { data: fontFamilySecondary, loading: loadingFontFamilySecondary } = useGetOne<
        string,
        string
    >({
        resource: RESOURCES.GENERAL,
        id: 'fontFamilySecondary',
    });

    const { data: about, loading: loadingAbout } = useGetOne<
        AboutResponse,
        AboutResponse
    >({ resource: RESOURCES.ROOT, id: 'about' });

    const { data: contact, loading: loadingContact } = useGetOne<
        ContactResponse,
        ContactResponse
    >({ resource: RESOURCES.ROOT, id: 'contact' });

    const { data: meta, loading: loadingMeta } = useGetOne<Meta, Meta>({
        resource: RESOURCES.ROOT,
        id: 'meta',
    });

    if (
        loadingDescription ||
        loadingLogo ||
        loadingFooter ||
        loadingFontFamilyPrimary ||
        loadingFontFamilySecondary ||
        loadingAbout ||
        loadingContact ||
        loadingMeta
    ) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <GeneralForm
                description={description ?? ''}
                logo={logo ?? ''}
                footer={footer ?? ''}
                fontFamilyPrimary={fontFamilyPrimary ?? ''}
                fontFamilySecondary={fontFamilySecondary ?? ''}
                about={about ?? ({} as AboutResponse)}
                contact={contact ?? ({} as ContactResponse)}
                meta={meta ?? ({} as Meta)}
            />
        </BackLayout>
    );
};
