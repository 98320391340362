import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { SubMenuDetailForm } from 'components/back/form/SubMenuDetailForm';

export const SubMenuCreate = () => {
    return (
        <BackLayout>
            <SubMenuDetailForm />
        </BackLayout>
    );
};
