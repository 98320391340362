export const round = (value: number, decimals: number) => {
    const checkDecimals = decimals > 0 ? decimals : 0;
    return (
        Math.round(value * Math.pow(10, checkDecimals)) /
        Math.pow(10, checkDecimals)
    );
};

export const formatPercentage = (value: number, decimals: number) =>
    `${round(value, decimals)}%`;

export const removeAccentMarks = (value: string): string => {
    const marks: { [key: string]: string } = {
        á: 'a',
        é: 'e',
        í: 'i',
        ó: 'o',
        ú: 'u',
        Á: 'A',
        É: 'E',
        Í: 'I',
        Ó: 'O',
        Ú: 'U',
        ñ: 'n',
        Ñ: 'N',
        ü: 'u',
        Ü: 'U',
    };

    return value.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, (c) => marks[c]);
};

export const stringIncludes = (base: string, contains: string): boolean =>
    removeAccentMarks(base)
        .toLowerCase()
        .includes(removeAccentMarks(contains).toLowerCase());
