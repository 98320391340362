import React from 'react';
import clsx from 'clsx';
import { IconBootstrap } from '../Icon';

type Props = {
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
};

export const ViewButton = ({
    className,
    disabled = false,
    onClick,
}: Props) => {
    return (
        <IconBootstrap
            name="eye"
            className={clsx('ms-2', className)}
            disabled={disabled}
            onClick={onClick}
        />
    );
};
