import React from 'react';
import { IconBootstrap } from '../../shared/Icon';
import { Badge } from '../../shared/Badge';
import { useTranslation } from 'react-i18next';
import { TranslatedString } from '../../../core/models/TranslatedString';
import { DeleteButton } from '../../shared/button/DeleteButton';
import { Card } from '../../shared/Card';
import { OtherWorkResponse } from '../../../core/models/OtherWork';

type Props = {
    otherWork: OtherWorkResponse;
    onClick: () => void;
    onDelete: () => void;
};

export const OtherWorkCard = ({
    otherWork: { title, position, visible, year },
    onClick,
    onDelete,
}: Props) => {
    const { i18n } = useTranslation();

    return (
        <Card
            onClick={onClick}
            actions={
                <>
                    {year && <Badge className="ms-2">{year}</Badge>}
                    {position && <Badge className="ms-2">{position}</Badge>}
                    <DeleteButton onClick={onDelete} />
                </>
            }
            m={2}
            p={2}
        >
            <IconBootstrap
                name={visible ? 'eye' : 'eye-slash'}
                className="me-2"
            />
            <div className="d-flex flex-column">
                {i18n.languages.map((language) => (
                    <span key={language}>
                        <Badge className="mx-1">{language}</Badge>
                        {title?.[language as keyof TranslatedString]}
                    </span>
                ))}
            </div>
        </Card>
    );
};
