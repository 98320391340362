import React, { useState } from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { SubSubTypeResponse } from '../../../core/models/SubSubType';
import { SubSubTypeCard } from '../../../components/back/card/SubSubTypeCard';
import { save } from '../../../core/services/firebase/resources.service';
import { useNavigate } from 'react-router-dom';
import { RESOURCES } from '../../../core/constants/resources';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../../../components/shared/button/AddButton';
import { BACK } from '../../../core/constants/routes';
import { DeleteModal } from '../../../components/shared/modal/DeleteModal';

export const SubSubTypes = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [modalDelete, setModalDelete] = useState<SubSubTypeResponse | null>(
        null
    );

    const {
        data: subSubTypes,
        loading,
        mutation,
    } = useGetAll<SubSubTypeResponse, SubSubTypeResponse>({
        resource: RESOURCES.SUBSUBTYPES,
    });

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (subSubType: SubSubTypeResponse) => () => {
        navigate(BACK.SUBSUBTYPE_DETAIL(subSubType.id));
    };

    const handleCardDelete = (subSubType: SubSubTypeResponse) => () => {
        setModalDelete(subSubType);
    };

    const submitDelete = () => {
        if (modalDelete) {
            save(RESOURCES.SUBSUBTYPES, modalDelete.id, {
                ...modalDelete,
                deleted: true,
            });
            setModalDelete(null);
            mutation();
        }
    };

    return (
        <BackLayout>
            <AddButton
                floating
                onClick={() => navigate(BACK.SUBSUBTYPE_CREATE)}
            />
            {subSubTypes.map((subSubType) => (
                <SubSubTypeCard
                    key={subSubType.id}
                    subSubType={subSubType}
                    onClick={handleCardClick(subSubType)}
                    onDelete={handleCardDelete(subSubType)}
                />
            ))}
            <DeleteModal
                show={!!modalDelete}
                title={t('pages.back.subSubTypes.modal.delete.title')}
                onDelete={submitDelete}
                onClose={() => setModalDelete(null)}
            >
                <p>{t('pages.back.subSubTypes.modal.delete.message')}</p>
            </DeleteModal>
        </BackLayout>
    );
};
