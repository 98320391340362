import { Team, TeamResponse } from '../models/Project';

export const teamMapper = (team: TeamResponse): Team => {
    return {
        artDirector: team?.artDirector ?? '',
        cast: team?.cast ?? '',
        color: team?.color ?? '',
        costumeDesigner: team?.costumeDesigner ?? '',
        directedBy: team?.directedBy ?? '',
        distribution: team?.distribution ?? '',
        edition: team?.edition ?? '',
        makeUp: team?.makeUp ?? '',
        music: team?.music ?? '',
        photographyDirector: team?.photographyDirector ?? '',
        production: team?.production ?? '',
        screenWriter: team?.screenWriter ?? '',
        sound: team?.sound ?? '',
        graphicDesign: team?.graphicDesign ?? '',
        originalIdea: team?.originalIdea ?? '',
        vfx: team?.vfx ?? '',
        author: team?.author ?? '',
        illustrator: team?.illustrator ?? '',
        editor: team?.editor ?? '',
    };
};
