import React from 'react';
import { ProjectResponse } from '../../../core/models/Project';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '../../shared/Box';
import { Type } from '../../../core/models/Type';
import { Select } from '../../shared/form/Select';
import { convertTypeToSelectOption } from '../../../core/mapper/typeMapper';
import { Checkbox } from '../../shared/form/Checkbox';
import { SubType } from '../../../core/models/SubType';
import { convertSubTypeToSelectOption } from '../../../core/mapper/subTypeMapper';
import { convertSubSubTypeToSelectOption } from '../../../core/mapper/subSubTypeMapper';
import { SubSubType } from '../../../core/models/SubSubType';
import { TextArea } from '../../shared/form/TextArea';
import { convertGenderToSelectOption } from '../../../core/mapper/genderMapper';
import { Gender } from '../../../core/models/Gender';
import { Row } from '../../shared/Row';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK } from '../../../core/constants/routes';
import { H5 } from '../../shared/H';
import { Col } from '../../shared/Col';
import { SaveButton } from '../../shared/button/SaveButton';
import { Button } from '../../shared/button/Button';
import {
    append,
    save,
} from '../../../core/services/firebase/resources.service';
import { DeleteButton } from '../../shared/button/DeleteButton';
import { Award } from '../../../core/models/Award';
import { convertAwardToSelectOption } from '../../../core/mapper/awardMapper';
import { TEAM_ROLES } from 'core/constants/teamRoles';

type Props = {
    project?: ProjectResponse;
    types: Type[];
    subTypes: SubType[];
    subSubTypes: SubSubType[];
    genders: Gender[];
    awards: Award[];
};

export const ProjectDetailForm = ({
    project,
    types,
    subTypes,
    subSubTypes,
    genders,
    awards,
}: Props) => {
    const T_RESOURCE = 'pages.back.projects.detail';

    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const methods = useForm<ProjectResponse>({
        defaultValues: project,
    });

    const { handleSubmit, setValue, control } = methods;

    const {
        fields: gallery,
        append: addGallery,
        remove: removeGallery,
    } = useFieldArray({
        control,
        name: 'gallery',
    });

    const onSubmit = async (data: ProjectResponse) => {
        const handledData = {
            ...data,
            duration: isNaN(data.duration) ? 0 : data.duration,
            position: isNaN(data.position) ? 0 : data.position,
            year:
                !data.year || data.year === 0 || isNaN(data.year)
                    ? null
                    : data.year,
            genders: data.genders === undefined ? [] : data.genders,
            gallery: data.gallery === undefined ? [] : data.gallery,
            awards: data.awards === undefined ? [] : data.awards,
        };
        if (project) {
            save(RESOURCES.PROJECTS, id, handledData);
        } else {
            append(RESOURCES.PROJECTS, handledData);
        }
        navigate(BACK.PROJECTS);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButton floating submit />
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="hash"
                            label={t(`${T_RESOURCE}.fields.hash`)}
                            required
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                setValue(
                                    'hash',
                                    e.target.value.replace(' ', '-')
                                )
                            }
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            type="number"
                            name="position"
                            label={t(`${T_RESOURCE}.fields.position`)}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-4">
                        <Select
                            name="type"
                            label={t(`${T_RESOURCE}.fields.type`)}
                            options={types.map(convertTypeToSelectOption)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Select
                            name="subType"
                            label={t(`${T_RESOURCE}.fields.subType`)}
                            options={subTypes.map(convertSubTypeToSelectOption)}
                            emptyOption
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Select
                            name="subSubType"
                            label={t(`${T_RESOURCE}.fields.subSubType`)}
                            options={subSubTypes.map(
                                convertSubSubTypeToSelectOption
                            )}
                            emptyOption
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-4">
                        <Select
                            name="genders"
                            multiple
                            label={t(`${T_RESOURCE}.fields.genders`)}
                            options={genders.map(convertGenderToSelectOption)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Select
                            name="awards"
                            multiple
                            label={t(`${T_RESOURCE}.fields.awards`)}
                            options={awards.map(convertAwardToSelectOption)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Checkbox
                            name="visible"
                            label={t(`${T_RESOURCE}.fields.visible`)}
                        />
                    </Col>
                </Row>
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <Input
                                name={`title.${language}`}
                                label={t(`${T_RESOURCE}.fields.title`, {
                                    language,
                                })}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <TextArea
                                richText
                                name={`description.${language}`}
                                label={t(`${T_RESOURCE}.fields.description`, {
                                    language,
                                })}
                                rows={10}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col px={1}>
                        <Input
                            name="image"
                            label={t(`${T_RESOURCE}.fields.image`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1}>
                        <Input
                            name="youTubeVideo"
                            label={t(`${T_RESOURCE}.fields.youTubeVideo`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1}>
                        <Input
                            name="vimeoVideo"
                            label={t(`${T_RESOURCE}.fields.vimeoVideo`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            type="number"
                            name="year"
                            label={t(`${T_RESOURCE}.fields.year`)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            type="number"
                            name="duration"
                            label={t(`${T_RESOURCE}.fields.duration`)}
                        />
                    </Col>
                </Row>
                <Box className="p-2 mb-2">
                    <H5 className="mb-2">
                        {t(`${T_RESOURCE}.fields.gallery.title`)}
                    </H5>
                    {gallery.map((item, index) => (
                        <Row key={item.id}>
                            <div className="p-0 d-flex flex-row align-items-center">
                                <div className="flex-fill">
                                    <Input name={`gallery.${index}.url`} />
                                </div>
                                <DeleteButton
                                    onClick={() => removeGallery(index)}
                                />
                            </div>
                        </Row>
                    ))}
                    <Button
                        outlined
                        className="mt-2"
                        onClick={() => {
                            addGallery({ url: '' });
                        }}
                    >
                        {t(`${T_RESOURCE}.actions.addGallery`)}
                    </Button>
                </Box>
                <Box className="p-2">
                    <H5>{t(`${T_RESOURCE}.fields.team.title`)}</H5>
                    <Row>
                        {TEAM_ROLES.map((role) => (
                            <Col key={role} px={1} className="col-12 col-md-3">
                                <Input
                                    name={`team.${role}`}
                                    label={t(
                                        `${T_RESOURCE}.fields.team.${role}`
                                    )}
                                />
                            </Col>
                        ))}
                    </Row>
                </Box>
            </form>
        </FormProvider>
    );
};
