import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { GenderDetailForm } from '../../../components/back/form/GenderDetailForm';

export const GenderCreate = () => {
    return (
        <BackLayout>
            <GenderDetailForm />
        </BackLayout>
    );
};
