import 'firebase/database';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const config = {
    apiKey: 'AIzaSyBSx3qJo7gnfrUfDwdA-wBXu-RNNuXx02k',
    authDomain: 'cv-galadiaz-ff8f5.firebaseapp.com',
    databaseURL:
        'https://cv-galadiaz-ff8f5-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'cv-galadiaz-ff8f5',
    storageBucket: 'cv-galadiaz-ff8f5.appspot.com',
    messagingSenderId: '518527938160',
    appId: '1:518527938160:web:330f52d65a4e7b6c9e0eb5',
};

const app = initializeApp(config);
const auth = getAuth(app);
const db = getDatabase(app);

const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
    }
};

const logout = () => {
    signOut(auth);
};

export { app, auth, db, logInWithEmailAndPassword, logout };
