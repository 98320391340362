import React, { useState } from 'react';
import { Project } from '../../core/models/Project';
import { useNavigate } from 'react-router-dom';
import { PROJECT_DETAIL } from '../../core/constants/routes';
import { useGetOne } from 'core/hooks/firebase/useGetOne';
import { RESOURCES } from 'core/constants/resources';
import clsx from 'clsx';

type Props = {
    project: Project;
};

export const ProjectCardv2 = ({
    project: { hash, title, image, subSubType },
}: Props) => {
    const navigate = useNavigate();

    const [over, setOver] = useState(false);

    const onMouseOver = () => setOver(true);
    const onMouseOut = () => setOver(false);

    const { data: fontFamilySecondary } = useGetOne({
        resource: RESOURCES.GENERAL,
        id: 'fontFamilySecondary',
    });

    return (
        <div
            className="card position-relative rounded-0 border-0 my-2 mx-1"
            role="button"
            onClick={() => navigate(PROJECT_DETAIL(hash))}
        >
            <img
                className="card-img-top rounded-0"
                style={{
                    opacity: over ? 0.4 : 1,
                    width: '100%',
                    minHeight: '100px',
                    objectFit: 'cover',
                }}
                src={image ? image : 'https://placehold.co/600x400'}
                alt={title}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            />
            {over && (
                <div
                    className="card-img-overlay position-absolute d-flex flex-column justify-content-center align-items-center"
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    <h5
                        className={clsx(
                            'card-title user-select-none text-center',
                            `font-${fontFamilySecondary}`
                        )}
                    >
                        {title}
                    </h5>
                    {subSubType && (
                        <span className="me-1">{subSubType.text}</span>
                    )}
                </div>
            )}
        </div>
    );
};
