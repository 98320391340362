import React from 'react';
import clsx from 'clsx';
import { Card } from './Card';
import { MarginType, PaddingType } from './type/BootstrapType';

type Props = {
    className?: string;
    m?: MarginType;
    p?: PaddingType;
};

export const EmptySection = ({ className, m = 0, p = 0 }: Props) => {
    return (
        <Card className={clsx(className, `m-${m}`, `p-${p}`)}>
            EMPTY SECTION
        </Card>
    );
};
