import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { SubTypeResponse } from '../../../core/models/SubType';
import { SubTypeDetailForm } from '../../../components/back/form/SubTypeDetailForm';
import { RESOURCES } from 'core/constants/resources';

export const SubTypeDetail = () => {
    const { id: subTypeId } = useParams();

    const { data: subType, loading } = useGetOne<
        SubTypeResponse,
        SubTypeResponse
    >({ resource: RESOURCES.SUBTYPES, id: subTypeId });

    if (loading || !subType) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <SubTypeDetailForm subType={subType} />
        </BackLayout>
    );
};
