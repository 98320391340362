import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { SubSubTypeDetailForm } from '../../../components/back/form/SubSubTypeDetailForm';

export const SubSubTypeCreate = () => {
    return (
        <BackLayout>
            <SubSubTypeDetailForm />
        </BackLayout>
    );
};
