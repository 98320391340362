import React from 'react';
import clsx from 'clsx';
import { ColorTypeSmall, ColorTypeBig } from './type/BootstrapType';

type Props = {
    color?: ColorTypeSmall;
    bg?: ColorTypeBig;
};

export const Spinner = ({ color = 'primary', bg = 'white' }: Props) => {
    return (
        <div
            className={clsx(
                'd-flex align-items-center justify-content-center vh-100',
                { [`bg-${bg}`]: bg }
            )}
        >
            <div
                className={clsx('spinner-border', { [`text-${color}`]: color })}
                role="status"
            >
                <span className="visually-hidden"></span>
            </div>
        </div>
    );
};
