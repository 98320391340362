export const FONT_FAMILIES = [
    { key: 'butler', name: 'Butler' },
    { key: 'inter', name: 'Inter' },
    { key: 'roboto', name: 'Roboto' },
    { key: 'noto-sans', name: 'Noto-Sans' },
    { key: 'lora', name: 'Lora' },
    { key: 'im_fell_double_pica', name: 'IM Fell Double Pica' },
    { key: 'im_fell_dw_pica', name: 'IM Fell DW Pica' },
    { key: 'cormorant_infant', name: 'Cormorant Infant' },
    { key: 'noto_serif_tibetan', name: 'Noto Serif Tibetan' },
];
