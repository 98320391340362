import React, { useState } from 'react';
import { Row } from 'components/shared/Row';
import { Col } from 'components/shared/Col';
import { IconBootstrap } from 'components/shared/Icon';
import { Input } from 'components/shared/form/Input';
import { useTranslation } from 'react-i18next';
import '../../styles/search-input.css';
import { FormProvider, useForm } from 'react-hook-form';
import { useProjectsFilterStore } from 'core/store/projectsFilter';

const ProjectSearchInput = React.memo(() => {
    const { t } = useTranslation();
    const methods = useForm();

    const [displayInput, setDisplayInput] = useState(false);

    const setSearch = useProjectsFilterStore((state) => state.setSearch);

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                    <Col
                        px={1}
                        className="col-12 d-flex align-items-center"
                        style={{ transform: 'scale(0.7)' }}
                    >
                        <IconBootstrap
                            name="search"
                            className="me-2 py-1"
                            color="body-tertiary"
                            onClick={() => setDisplayInput(!displayInput)}
                        />
                        {displayInput && (
                            <Input
                                name="searchTerm"
                                marginBottom={0}
                                className="flex-fill search-input"
                                placeholder={t('pages.home.search')}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        )}
                    </Col>
                </Row>
            </form>
        </FormProvider>
    );
});

ProjectSearchInput.displayName = 'ProjectSearchInput';

export default ProjectSearchInput;
