import { Award, AwardResponse } from '../models/Award';

export const awardMapper = (award: AwardResponse): Award => {
    return {
        id: award?.id ?? '',
        name: award?.name ?? '',
        image: award?.image ?? '',
        size: award?.size ?? '',
        displayModal: award?.displayModal ?? false,
    };
};

export const convertAwardToSelectOption = (
    award: Award
): { key: string; name: string } => ({ key: award.id, name: award.name });
