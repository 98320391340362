import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { save } from '../../../core/services/firebase/resources.service';
import { Checkbox } from '../../shared/form/Checkbox';
import { Language } from '../../../core/models/Language';
import { Row } from '../../shared/Row';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK } from '../../../core/constants/routes';
import { Col } from '../../shared/Col';
import { SaveButton } from '../../shared/button/SaveButton';

type Props = {
    language: Language;
};

export const LanguageDetailForm = ({ language }: Props) => {
    const T_RESOURCE = 'pages.back.languages.detail';

    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const methods = useForm({
        defaultValues: language,
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data: Language) => {
        save(RESOURCES.LANGUAGES, id, data);
        navigate(BACK.LANGUAGES);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButton floating submit />
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="code"
                            label={t(`${T_RESOURCE}.fields.code`)}
                            required
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-4">
                        <Input
                            name="name"
                            label={t(`${T_RESOURCE}.fields.title`)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Checkbox
                            name="visible"
                            label={t(`${T_RESOURCE}.fields.visible`)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Input
                            type="number"
                            name="position"
                            label={t(`${T_RESOURCE}.fields.position`)}
                        />
                    </Col>
                </Row>
            </form>
        </FormProvider>
    );
};
