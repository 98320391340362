import React, { useState } from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { save } from '../../../core/services/firebase/resources.service';
import { useNavigate } from 'react-router-dom';
import { RESOURCES } from '../../../core/constants/resources';
import { AddButton } from '../../../components/shared/button/AddButton';
import { useTranslation } from 'react-i18next';
import { BACK, OTHER_WORK } from '../../../core/constants/routes';
import { DeleteModal } from '../../../components/shared/modal/DeleteModal';
import { OtherWorkResponse } from '../../../core/models/OtherWork';
import { OtherWorkCard } from '../../../components/back/card/OtherWorkCard';
import { SortDirection } from '../../../core/models/shared/SortDirection';
import { Button } from 'components/shared/button/Button';
import { IconBootstrap } from 'components/shared/Icon';

export const OtherWorks = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [modalDelete, setModalDelete] = useState<OtherWorkResponse | null>(
        null
    );

    const {
        data: otherWorks,
        loading,
        mutation,
    } = useGetAll<OtherWorkResponse, OtherWorkResponse>({
        resource: RESOURCES.OTHER_WORKS,
        mapper: (obj) => obj,
        filters: undefined,
        sort: { key: 'position', direction: SortDirection.DESC },
    });

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (otherWork: OtherWorkResponse) => () => {
        navigate(BACK.OTHER_WORK_DETAIL(otherWork.id));
    };

    const handleCardDelete = (otherWork: OtherWorkResponse) => () => {
        setModalDelete(otherWork);
    };

    const submitDelete = () => {
        if (modalDelete) {
            save(RESOURCES.OTHER_WORKS, modalDelete.id, {
                ...modalDelete,
                deleted: true,
            });
            setModalDelete(null);
            mutation();
        }
    };

    return (
        <BackLayout>
            <AddButton
                floating
                onClick={() => navigate(BACK.OTHER_WORK_CREATE)}
            />
            <div className="d-flex justify-content-center">
                <Button outlined onClick={() => navigate(OTHER_WORK)}>
                    <IconBootstrap name="eye" />
                </Button>
            </div>
            {otherWorks.map((otherWork) => (
                <OtherWorkCard
                    key={otherWork.id}
                    otherWork={otherWork}
                    onClick={handleCardClick(otherWork)}
                    onDelete={handleCardDelete(otherWork)}
                />
            ))}
            <DeleteModal
                show={!!modalDelete}
                title={t('pages.back.otherWorks.modal.delete.title')}
                onDelete={submitDelete}
                onClose={() => setModalDelete(null)}
            >
                <p>{t('pages.back.otherWorks.modal.delete.message')}</p>
            </DeleteModal>
        </BackLayout>
    );
};
