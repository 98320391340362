import React from 'react';
import { DeleteButton } from '../../shared/button/DeleteButton';
import { Card } from '../../shared/Card';
import { AwardResponse } from '../../../core/models/Award';
import { Badge } from 'components/shared/Badge';
import { useTranslation } from 'react-i18next';

type Props = {
    award: AwardResponse;
    onClick: () => void;
    onDelete: () => void;
};

export const AwardCard = ({
    award: { name, size, displayModal },
    onClick,
    onDelete,
}: Props) => {
    const { t } = useTranslation();
    return (
        <Card
            onClick={onClick}
            actions={
                <>
                    {size && <Badge>{size}</Badge>}
                    {displayModal && (
                        <Badge color="secondary" className="ms-2">
                            {t('pages.back.awards.detail.fields.displayModal')}
                        </Badge>
                    )}
                    <DeleteButton onClick={onDelete} />
                </>
            }
            m={2}
            p={2}
        >
            {name}
        </Card>
    );
};
