import React, { ReactNode } from 'react';
import { Menu } from './Menu';
import { Breadcrumb } from 'components/shared/Breadcrumb';

type Props = {
    children?: ReactNode;
    breadcrumbs?: { title: string; url?: string }[];
};

export const BackLayout = ({ breadcrumbs, children }: Props) => {
    return (
        <div className="container-fluid pb-5">
            <Menu />
            <Breadcrumb.Root>
                {breadcrumbs?.map(({ title, url }) => (
                    <Breadcrumb.Item key={title} title={title} url={url} />
                ))}
            </Breadcrumb.Root>
            {children}
        </div>
    );
};
