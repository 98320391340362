import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Box } from './Box';
import {
    ColorTypeSmall,
    ColorTypeBig,
    MarginType,
    PaddingType,
} from './type/BootstrapType';

type Props = {
    border?: ColorTypeSmall;
    background?: ColorTypeBig;
    text?: ColorTypeBig;
    children: ReactNode;
    actions?: ReactNode;
    onClick?: () => void;
    className?: string;
    inline?: boolean;
    m?: MarginType;
    p?: PaddingType;
};

export const Card = ({
    border,
    background,
    text,
    children,
    actions,
    onClick,
    className,
    inline = false,
    m = 0,
    p = 0,
}: Props) => {
    return (
        <Box
            className={clsx(
                'd-flex align-items-center align-items-md-center',
                className,
                `m-${m}`,
                `p-${p}`,
                {
                    'flex-row': inline,
                    'flex-column flex-md-row': !inline,
                    pointer: onClick,
                }
            )}
            border={border}
            background={background}
            text={text}
            onClick={onClick}
        >
            <div className="d-flex flex-row align-items-center flex-grow-1 w-100">
                {children}
            </div>
            <div className="d-flex flex-row align-items-center">{actions}</div>
        </Box>
    );
};
