import React from 'react';
import { Project } from '../../core/models/Project';
import { ProjectTeam } from './ProjectTeam';
import { ProjectGallery } from './ProjectGallery';
import { ProjectTitle } from './ProjectTitle';
import { ProjectColumn } from './ProjectColumn';

type Props = {
    project: Project;
    titleSize: number;
    contentSize: number;
};

export const ProjectDetailPhoto = ({
    project,
    titleSize,
    contentSize,
}: Props) => {
    const { title, description, year, subSubType, team, gallery, awards } =
        project;

    return (
        <>
            <ProjectGallery gallery={gallery} />
            <ProjectTitle title={title} size={titleSize} />
            <div style={{ fontSize: contentSize }}>
                {subSubType && (
                    <span className="m-2 ms-0">{subSubType.text}</span>
                )}
                {year && <span className="my-2">{year}</span>}
                <ProjectColumn awards={awards}>
                    {description && (
                        <p
                            className="my-3"
                            style={{ textAlign: 'justify' }}
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        ></p>
                    )}
                    <div className="my-2">
                        <ProjectTeam team={team} />
                    </div>
                </ProjectColumn>
            </div>
        </>
    );
};
