import React from 'react';
import { BackLayout } from '../../components/layouts/back/BackLayout';
import { Home } from 'pages/Home';
import { Header } from 'components/layouts/main/Header';

export const TestA = () => {
    return (
        <BackLayout>
            <Header
                height="100px"
                breakPoint="md"
                isTopFixed={false}
                displaySearchInput
            />
            <Home displayHeader={false} />
        </BackLayout>
    );
};
