import React, { useState } from 'react';
import { RegisterOptions, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconBootstrap } from '../Icon';
import { Button } from '../button/Button';
import { FormLabel } from './FormLabel';
import { getByPath } from '../../../core/utils/object.util';
import { ErrorLabel } from './ErrorLabel';

type Props = {
    label?: string | null;
    type?: 'text' | 'password' | 'number' | 'date' | 'email';
    placeholder?: string | null;
    name?: string;
    passwordReveal?: boolean; // TODO: passwordReveal is only allowed on type password
    required?: boolean;
    positive?: boolean;
    size?: 'lg' | 'sm';
    disabled?: boolean;
    marginBottom?: number;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    validate?: NonNullable<
        Parameters<ReturnType<typeof useForm>['register']>[1]
    >['validate'];
};

export const Input = ({
    label,
    type = 'text',
    placeholder,
    name,
    passwordReveal = false,
    required = false,
    positive = false,
    size,
    disabled = false,
    marginBottom = 2,
    className,
    onChange,
    validate,
}: Props) => {
    const { t } = useTranslation();
    const methods = useFormContext();

    const isPassword = type === 'password';
    const isNumber = type === 'number';
    const isDate = type === 'date';

    const autocompleteProp = isPassword ? { autoComplete: 'on' } : {};

    const requiredObject = {
        required: t('validation.required'),
    };

    const positiveObject = {
        positive: (v: string) => parseFloat(v) > 0 || t('validation.positive'),
    };

    const validateOptions = {
        ...(positive ? positiveObject : {}),
        ...validate,
    };

    const registerOptions = {
        ...(required ? requiredObject : {}),
        valueAsNumber: isNumber ? true : undefined,
        valueAsDate: isDate ? undefined : false,
        disabled: disabled,
        onChange,
        validate: validateOptions,
    } as RegisterOptions;

    const registration = name ? methods?.register(name, registerOptions) : null;
    const [showPassword, setShowPassword] = useState(false);

    const inputNodeType = showPassword ? 'text' : type;

    const hasError = name && !!getByPath(methods?.formState?.errors, name);

    const InputNode = () => (
        <input
            {...registration}
            {...autocompleteProp}
            step=".01"
            type={inputNodeType}
            className={clsx('form-control', {
                [`form-control-${size}`]: size,
                'border border-danger': hasError,
            })}
            placeholder={placeholder ?? label ?? ''}
            disabled={disabled}
        />
    );

    const showGroup = type === 'password' && passwordReveal;

    const handleEyeClick = () => setShowPassword(!showPassword);

    return (
        <div className={clsx(`mb-${marginBottom} text-start`, className)}>
            {label && <FormLabel label={label} />}
            {!showGroup && <InputNode />}
            {showGroup && (
                <div className="input-group">
                    {/* TODO: when onChange and the required error is removed, focus is out */}
                    <InputNode />
                    <Button
                        variant="link"
                        outlined
                        className={clsx('rounded-end', {
                            'border-danger': hasError,
                            'border-secondary-subtle': !hasError,
                        })}
                        onClick={handleEyeClick}
                    >
                        {!showPassword && (
                            <IconBootstrap name="eye" color="secondary" />
                        )}
                        {showPassword && (
                            <IconBootstrap name="eye-slash" color="secondary" />
                        )}
                    </Button>
                </div>
            )}
            {name && <ErrorLabel name={name} />}
        </div>
    );
};
