import React, { useEffect } from 'react';
import { MainLayout } from '../components/layouts/main/MainLayout';
import { useTranslation } from 'react-i18next';
import { useGetOne } from '../core/hooks/firebase/useGetOne';
import { aboutMapper } from '../core/mapper/aboutMapper';
import { About as AboutModel, AboutResponse } from '../core/models/About';
import { Row } from '../components/shared/Row';
import { RESOURCES } from '../core/constants/resources';

export const About = () => {
    const { i18n } = useTranslation();

    const { data: about, mutation: aboutMutation } = useGetOne<
        AboutModel,
        AboutResponse
    >({
        resource: RESOURCES.ROOT,
        id: 'about',
        mapper: aboutMapper(i18n.language),
    });

    useEffect(() => {
        aboutMutation();
    }, [i18n.language]);

    return (
        <MainLayout>
            <Row>
                <div className="col-12 col-md-6">
                    <img src={about?.image} className="img-fluid" />
                </div>
                <div className="col-12 col-md-6">
                    <div
                        style={{ textAlign: 'justify' }}
                        dangerouslySetInnerHTML={{
                            __html: about?.content ?? '',
                        }}
                    ></div>
                </div>
            </Row>
        </MainLayout>
    );
};
