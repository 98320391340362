import React from 'react';
import { Team } from '../../core/models/Project';
import { useTranslation } from 'react-i18next';
import { useGetOne } from 'core/hooks/firebase/useGetOne';
import { RESOURCES } from 'core/constants/resources';
import clsx from 'clsx';
import { TEAM_ROLES } from 'core/constants/teamRoles';

type Props = {
    team: Team | null;
};

export const ProjectTeam = ({ team }: Props) => {
    const { t } = useTranslation();

    const { data: fontFamilyPrimary } = useGetOne({
        resource: RESOURCES.GENERAL,
        id: 'fontFamilyPrimary',
    });

    const { data: fontFamilySecondary } = useGetOne({
        resource: RESOURCES.GENERAL,
        id: 'fontFamilySecondary',
    });

    return (
        <div>
            {TEAM_ROLES.map((role) =>
                team?.[role as keyof Team] ? (
                    <p key={role} className="mb-1">
                        <strong className={clsx(`font-${fontFamilySecondary}`)}>
                            {t(`pages.project.fields.team.${role}`)}{' '}
                        </strong>
                        <span className={clsx(`font-${fontFamilyPrimary}`)}>
                            {team?.[role as keyof Team]}
                        </span>
                    </p>
                ) : null
            )}
        </div>
    );
};
