import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form/Input';
import { save } from '../../../core/services/firebase/resources.service';
import { Row } from '../../shared/Row';
import { TextArea } from '../../shared/form/TextArea';
import { RESOURCES } from '../../../core/constants/resources';
import { useNavigate } from 'react-router-dom';
import { AboutResponse } from '../../../core/models/About';
import { Box } from '../../shared/Box';
import { H5 } from '../../shared/H';
import { ContactResponse } from '../../../core/models/Contact';
import { Meta } from '../../../core/models/shared/Meta';
import { Col } from '../../shared/Col';
import { SaveButton } from '../../shared/button/SaveButton';
import { IconBootstrap } from 'components/shared/Icon';
import { ABOUT } from 'core/constants/routes';
import { Select } from 'components/shared/form/Select';
import { FONT_FAMILIES } from 'core/constants/fontFamily';

type Props = {
    description: string;
    logo: string;
    footer: string;
    fontFamilyPrimary: string;
    fontFamilySecondary: string;
    about: AboutResponse;
    contact: ContactResponse;
    meta: Meta;
};

export const GeneralForm = ({
    description,
    logo,
    footer,
    fontFamilyPrimary,
    fontFamilySecondary,
    about,
    contact,
    meta,
}: Props) => {
    const T_RESOURCE = 'pages.back.general.detail';

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const methods = useForm({
        defaultValues: {
            description,
            logo,
            footer,
            fontFamilyPrimary,
            fontFamilySecondary,
            about,
            contact,
            meta,
        },
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data: Props) => {
        save(RESOURCES.GENERAL, 'description', data.description);
        save(RESOURCES.GENERAL, 'logo', data.logo);
        save(RESOURCES.GENERAL, 'footer', data.footer);
        save(RESOURCES.GENERAL, 'fontFamilyPrimary', data.fontFamilyPrimary);
        save(
            RESOURCES.GENERAL,
            'fontFamilySecondary',
            data.fontFamilySecondary
        );
        save(RESOURCES.ROOT, 'about', data.about);
        save(RESOURCES.ROOT, 'contact', data.contact);
        save(RESOURCES.ROOT, 'meta', data.meta);
        navigate(0);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButton floating submit />
                <Row>
                    <Col px={1}>
                        <Input
                            name="description"
                            label={t(`${T_RESOURCE}.fields.description`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1}>
                        <Input
                            name="logo"
                            label={t(`${T_RESOURCE}.fields.logo`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1}>
                        <TextArea
                            richText
                            name="footer"
                            label={t(`${T_RESOURCE}.fields.footer`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Select
                            label={t(`${T_RESOURCE}.fields.fontFamilyPrimary`)}
                            name="fontFamilyPrimary"
                            options={FONT_FAMILIES}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-6">
                        <Select
                            label={t(
                                `${T_RESOURCE}.fields.fontFamilySecondary`
                            )}
                            name="fontFamilySecondary"
                            options={FONT_FAMILIES}
                        />
                    </Col>
                </Row>
                <Box className="p-2">
                    <H5 className="mb-1">
                        {t(`${T_RESOURCE}.title.about`)}
                        <IconBootstrap
                            name="eye"
                            className="ms-2"
                            onClick={() => navigate(ABOUT)}
                        />
                    </H5>
                    <Row>
                        <Col px={1}>
                            <Input
                                name="about.image"
                                label={t(`${T_RESOURCE}.fields.about.image`)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {i18n.languages.map((language) => (
                            <Col
                                key={language}
                                px={1}
                                className="col-12 col-md-4"
                            >
                                <TextArea
                                    richText
                                    name={`about.content.${language}`}
                                    label={t(
                                        `${T_RESOURCE}.fields.about.content`,
                                        { language }
                                    )}
                                    rows={15}
                                />
                            </Col>
                        ))}
                    </Row>
                </Box>
                <Box className="p-2 mt-2">
                    <H5 className="mb-1">
                        {t(`${T_RESOURCE}.title.contact`)}
                        <IconBootstrap
                            name="eye"
                            className="ms-2"
                            onClick={() => navigate(ABOUT)}
                        />
                    </H5>
                    <Row>
                        <Col px={1} className="col-12 col-md-6">
                            <Input
                                name="contact.name"
                                label={t(`${T_RESOURCE}.fields.contact.name`)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col px={1} className="col-12 col-md-6">
                            <Input
                                name="contact.email"
                                label={t(`${T_RESOURCE}.fields.contact.email`)}
                            />
                        </Col>
                        <Col px={1} className="col-12 col-md-6">
                            <Input
                                name="contact.phone"
                                label={t(`${T_RESOURCE}.fields.contact.phone`)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col px={1}>
                            <Input
                                name="contact.instagram"
                                label={t(
                                    `${T_RESOURCE}.fields.contact.instagram`
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col px={1}>
                            <Input
                                name="contact.imdb"
                                label={t(`${T_RESOURCE}.fields.contact.imdb`)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col px={1}>
                            <Input
                                name="contact.linkedin"
                                label={t(
                                    `${T_RESOURCE}.fields.contact.linkedin`
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col px={1}>
                            <Input
                                name="contact.image"
                                label={t(`${T_RESOURCE}.fields.contact.image`)}
                            />
                        </Col>
                    </Row>
                </Box>
                <Box className="p-2 mt-2">
                    <H5 className="mb-1">{t(`${T_RESOURCE}.title.meta`)}</H5>
                    <Row>
                        <Col px={1} className="col-12 col-md-6">
                            <Input
                                name="meta.title"
                                label={t(`${T_RESOURCE}.fields.meta.title`)}
                            />
                        </Col>
                        <Col px={1} className="col-12 col-md-6">
                            <Input
                                name="meta.author"
                                label={t(`${T_RESOURCE}.fields.meta.author`)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col px={1}>
                            <Input
                                name="meta.favicon"
                                label={t(`${T_RESOURCE}.fields.meta.favicon`)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col px={1}>
                            <TextArea
                                name="meta.description"
                                label={t(
                                    `${T_RESOURCE}.fields.meta.description`
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col px={1}>
                            <Input
                                name="meta.keywords"
                                label={t(`${T_RESOURCE}.fields.meta.keywords`)}
                            />
                        </Col>
                    </Row>
                </Box>
            </form>
        </FormProvider>
    );
};
