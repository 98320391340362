import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { SubSubTypeResponse } from '../../../core/models/SubSubType';
import { SubSubTypeDetailForm } from '../../../components/back/form/SubSubTypeDetailForm';
import { RESOURCES } from 'core/constants/resources';

export const SubSubTypeDetail = () => {
    const { id: subSubTypeId } = useParams();

    const { data: subSubType, loading } = useGetOne<
        SubSubTypeResponse,
        SubSubTypeResponse
    >({ resource: RESOURCES.SUBSUBTYPES, id: subSubTypeId });

    if (loading || !subSubType) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <SubSubTypeDetailForm subSubType={subSubType} />
        </BackLayout>
    );
};
