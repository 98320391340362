import { useEffect, useState } from 'react';
import { getAll } from 'core/services/firebase/resources.service';
import { Sort } from 'core/models/shared/Sort';
import { SortDirection } from 'core/models/shared/SortDirection';

type FilterFunction<R> = (response: R) => boolean;

type Props<T, R> = {
    resource: string;
    mapper?: (obj: R) => T;
    filters?: {
        [key: string]: string | number | boolean | FilterFunction<R>;
    };
    sort?: Sort;
    disabled?: boolean;
};
export const useGetAll = <T, R>({
    resource,
    mapper,
    filters,
    sort,
    disabled = false,
}: Props<T, R>) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T[]>([]);
    const mutation = async () => {
        if (disabled) {
            return;
        }
        setLoading(true);
        let responses = await getAll(resource);
        if (filters) {
            responses = responses.filter((response) =>
                Object.keys(filters).every((key) => {
                    if (!filters[key]) {
                        return true;
                    }
                    if (Array.isArray(response[key])) {
                        return response[key].includes(filters[key]);
                    }
                    if (typeof filters[key] === 'function') {
                        return (filters[key] as FilterFunction<R>)(response);
                    }
                    return response[key] === filters[key];
                })
            );
        }
        if (sort) {
            responses = responses.sort((a, b) => {
                const valueA = a[sort.key];
                const valueB = b[sort.key];

                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return valueA.localeCompare(valueB);
                } else if (
                    typeof valueA === 'number' &&
                    typeof valueB === 'number'
                ) {
                    return valueA - valueB;
                } else {
                    return String(valueA).localeCompare(String(valueB));
                }
            });
            if (sort.direction === SortDirection.DESC) {
                responses = responses.reverse();
            }
        }
        if (mapper) {
            setData(responses.map((r: R) => mapper(r)));
        } else {
            setData(responses);
        }
        setLoading(false);
    };
    useEffect(() => {
        mutation();
    }, [resource]);
    return { data, loading, mutation };
};
