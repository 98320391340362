import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { RESOURCES } from '../../../core/constants/resources';
import { MenuResponse } from 'core/models/Menu';
import { MenuDetailForm } from 'components/back/form/MenuDetailForm';

export const MenuDetail = () => {
    const { id: menuId } = useParams();

    const { data: menu, loading } = useGetOne<MenuResponse, MenuResponse>({
        resource: RESOURCES.MENU,
        id: menuId,
        mapper: (obj: MenuResponse) => ({
            ...obj,
            subItems: obj?.subItems ? Object.values(obj.subItems) : [],
        }),
    });

    if (loading || !menu) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <MenuDetailForm menu={menu} />
        </BackLayout>
    );
};
