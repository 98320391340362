import React, { MouseEventHandler } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { IconBootstrap } from '../Icon';
import 'react-image-gallery/styles/css/image-gallery.css';

// https://www.npmjs.com/package/react-image-gallery

type ItemType = {
    url: string;
};

const mapGalleryItem = (
    item: ItemType,
    height: number | undefined
): ReactImageGalleryItem => ({
    original: item.url,
    originalHeight: height,
});

type Props = {
    items: ItemType[];
    height?: number;
};

export const Gallery = ({ items = [], height }: Props) => {
    return (
        <ImageGallery
            items={items.map((item) => mapGalleryItem(item, height))}
            lazyLoad
            showFullscreenButton={false}
            showPlayButton={false}
            renderLeftNav={(
                onClick: MouseEventHandler<HTMLElement>,
                disabled: boolean
            ) => (
                <div
                    onClick={onClick}
                    className="image-gallery-icon image-gallery-left-nav"
                    role="button"
                >
                    <IconBootstrap
                        name="chevron-compact-left"
                        color="light"
                        disabled={disabled}
                    />
                </div>
            )}
            renderRightNav={(
                onClick: MouseEventHandler<HTMLElement>,
                disabled: boolean
            ) => (
                <div
                    onClick={onClick}
                    className="image-gallery-icon image-gallery-right-nav"
                    role="button"
                >
                    <IconBootstrap
                        name="chevron-compact-right"
                        color="light"
                        disabled={disabled}
                    />
                </div>
            )}
        />
    );
};
