import React, { useState } from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { SubTypeCard } from '../../../components/back/card/SubTypeCard';
import { SubTypeResponse } from '../../../core/models/SubType';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { save } from '../../../core/services/firebase/resources.service';
import { useNavigate } from 'react-router-dom';
import { RESOURCES } from '../../../core/constants/resources';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../../../components/shared/button/AddButton';
import { BACK } from '../../../core/constants/routes';
import { DeleteModal } from '../../../components/shared/modal/DeleteModal';

export const SubTypes = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [modalDelete, setModalDelete] = useState<SubTypeResponse | null>(
        null
    );

    const {
        data: subTypes,
        loading,
        mutation,
    } = useGetAll<SubTypeResponse, SubTypeResponse>({
        resource: RESOURCES.SUBTYPES,
    });

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (subType: SubTypeResponse) => () => {
        navigate(BACK.SUBTYPE_DETAIL(subType.id));
    };

    const handleCardDelete = (subType: SubTypeResponse) => () => {
        setModalDelete(subType);
    };

    const submitDelete = () => {
        if (modalDelete) {
            save(RESOURCES.SUBTYPES, modalDelete.id, {
                ...modalDelete,
                deleted: true,
            });
            setModalDelete(null);
            mutation();
        }
    };

    return (
        <BackLayout>
            <AddButton floating onClick={() => navigate(BACK.SUBTYPE_CREATE)} />
            {subTypes.map((subType) => (
                <SubTypeCard
                    key={subType.id}
                    subType={subType}
                    onClick={handleCardClick(subType)}
                    onDelete={handleCardDelete(subType)}
                />
            ))}
            <DeleteModal
                show={!!modalDelete}
                title={t('pages.back.subTypes.modal.delete.title')}
                onDelete={submitDelete}
                onClose={() => setModalDelete(null)}
            >
                <p>{t('pages.back.subTypes.modal.delete.message')}</p>
            </DeleteModal>
        </BackLayout>
    );
};
