import React from 'react';
import { BackLayout } from '../../components/layouts/back/BackLayout';
import { Header } from 'components/layouts/main/Header';
import { OtherWorks } from 'pages/OtherWorks';

export const TestB = () => {
    return (
        <BackLayout>
            <Header
                height="100px"
                breakPoint="md"
                isTopFixed={false}
                displaySearchInput
            />
            <OtherWorks displayHeader={false} />
        </BackLayout>
    );
};
