import { create } from 'zustand';

interface ProjectsFilterState {
    search: string;
    setSearch: (data: string) => void;
}

export const useProjectsFilterStore = create<ProjectsFilterState>((set) => ({
    search: '',
    setSearch: (data: string) => set(() => ({ search: data })),
}));
