import React from 'react';
import { GalleryImage } from 'core/models/GalleryImage';
import { Gallery } from 'components/shared/image/Gallery';
import { Row } from 'components/shared/Row';
import { Col } from 'components/shared/Col';

type Props = {
    gallery: GalleryImage[];
};

export const ProjectGallery = ({ gallery }: Props) => {
    return (
        <Row>
            <Col px={1} className="col-0 col-md-2" />
            <Col px={1} className="col-12 col-md-8">
                {gallery && gallery.length > 0 && (
                    <Gallery
                        items={gallery.map((item) => ({ url: item.url }))}
                    />
                )}
            </Col>
        </Row>
    );
};
