import React, { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import { MarginType, PaddingType } from './type/BootstrapType';

type Props = {
    children: ReactNode;
    className?: string;
    underlined?: boolean;
    lineThrough?: boolean;
    monospace?: boolean;
    italic?: boolean;
    lowercase?: boolean;
    uppercase?: boolean;
    capitalize?: boolean;
    bold?: boolean;
    style?: CSSProperties;
    m?: MarginType;
    p?: PaddingType;
    mt?: MarginType;
    mb?: MarginType;
    mx?: MarginType;
    my?: MarginType;
};

export const P = ({
    children,
    className,
    underlined = false,
    lineThrough = false,
    monospace = false,
    italic = false,
    lowercase = false,
    uppercase = false,
    capitalize = false,
    bold = false,
    style,
    m = 0,
    p = 0,
    mt,
    mb,
    mx,
    my,
}: Props) => {
    return (
        <p
            className={clsx(className, `m-${m}`, `p-${p}`, {
                'text-decoration-underline': underlined,
                'text-decoration-line-through': lineThrough,
                'font-monospace': monospace,
                'fst-italic': italic,
                'text-lowercase': lowercase,
                'text-uppercase': uppercase,
                'text-capitalize': capitalize,
                'fw-bold': bold,
                [`mt-${mt}`]: mt !== undefined,
                [`mb-${mb}`]: mb !== undefined,
                [`mx-${mx}`]: mx !== undefined,
                [`my-${my}`]: my !== undefined,
            })}
            style={style}
        >
            {children}
        </p>
    );
};
