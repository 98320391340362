import React, { useEffect } from 'react';
import { MainLayout } from '../components/layouts/main/MainLayout';
import { OtherWork, OtherWorkResponse } from '../core/models/OtherWork';
import { useGetAll } from '../core/hooks/firebase/useGetAll';
import { RESOURCES } from '../core/constants/resources';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../core/models/shared/SortDirection';
import { otherWorkMapper } from '../core/mapper/otherWorkMapper';
import { OtherWorkCard } from '../components/otherWork/OtherWorkCard';
import { filterOtherWork } from 'core/utils/other-credit-util';
import { useDebounce } from '@uidotdev/usehooks';
import { useProjectsFilterStore } from 'core/store/projectsFilter';

type Props = {
    displayHeader?: boolean;
};

export const OtherWorks = ({ displayHeader = true }: Props) => {
    const { i18n } = useTranslation();

    const searchTerm = useProjectsFilterStore((state) => state.search);
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    const { data: otherWorks, mutation: otherWorksMutation } = useGetAll<
        OtherWork,
        OtherWorkResponse
    >({
        resource: RESOURCES.OTHER_WORKS,
        mapper: otherWorkMapper(i18n.language),
        filters: {
            visible: true,
            ...(debouncedSearchTerm
                ? { searchFilter: filterOtherWork(debouncedSearchTerm, i18n.language) }
                : {}),
        },
        sort: { key: 'position', direction: SortDirection.DESC },
    });

    useEffect(() => {
        otherWorksMutation();
    }, [i18n.language, debouncedSearchTerm]);

    return (
        <MainLayout displayHeader={displayHeader}>
            {otherWorks.map((otherWork: OtherWork) => (
                <OtherWorkCard key={otherWork.id} otherWork={otherWork} />
            ))}
        </MainLayout>
    );
};
