import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form/Input';
import { useNavigate, useParams } from 'react-router-dom';
import {
    append,
    save,
} from '../../../core/services/firebase/resources.service';
import { Row } from '../../shared/Row';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK } from '../../../core/constants/routes';
import { Col } from '../../shared/Col';
import { AwardResponse } from '../../../core/models/Award';
import { SaveButton } from '../../shared/button/SaveButton';
import { Select } from 'components/shared/form/Select';
import { Checkbox } from 'components/shared/form/Checkbox';

type Props = {
    award?: AwardResponse;
};

export const AwardDetailForm = ({ award }: Props) => {
    const T_RESOURCE = 'pages.back.awards.detail';

    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const methods = useForm({
        defaultValues: award,
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data: AwardResponse) => {
        if (award) {
            save(RESOURCES.AWARDS, id, data);
        } else {
            append(RESOURCES.AWARDS, data);
        }
        navigate(BACK.AWARDS);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButton floating submit />
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="name"
                            label={t(`${T_RESOURCE}.fields.name`)}
                            required
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="image"
                            label={t(`${T_RESOURCE}.fields.image`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Select
                            name="size"
                            label={t(`${T_RESOURCE}.fields.size`)}
                            options={[
                                {
                                    key: 'medium',
                                    name: t(`${T_RESOURCE}.sizes.medium`),
                                },
                                {
                                    key: 'big',
                                    name: t(`${T_RESOURCE}.sizes.big`),
                                },
                            ]}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Checkbox
                            name="displayModal"
                            label={t(`${T_RESOURCE}.fields.displayModal`)}
                        />
                    </Col>
                </Row>
            </form>
        </FormProvider>
    );
};
