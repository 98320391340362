import { OtherWorkResponse } from 'core/models/OtherWork';
import { TranslatedString } from 'core/models/TranslatedString';
import { stringIncludes } from './string-util';

export const filterOtherWork =
    (searchTerm: string, language: string) => (otherWork: OtherWorkResponse): boolean => {
        const shouldDisplayByTitle = filterOtherWorkByTitle(
            otherWork,
            searchTerm,
            language
        );
        const shouldDisplayByDescription = filterOtherWorkByDescription(
            otherWork,
            searchTerm,
            language
        );
        const shouldDisplayByRol = filterOtherWorkByRol(
            otherWork,
            searchTerm,
            language
        );
        const shouldDisplayByDirection = filterOtherWorkByDirection(
            otherWork,
            searchTerm,
            language
        );
        const shouldDisplayByProductor = filterOtherWorkByProductor(
            otherWork,
            searchTerm
        );
        return (
            shouldDisplayByTitle ||
            shouldDisplayByDescription ||
            shouldDisplayByRol ||
            shouldDisplayByDirection ||
            shouldDisplayByProductor
        );
    };

export const filterOtherWorkByTitle = (
    { title }: OtherWorkResponse,
    searchTerm: string,
    language: string
): boolean => {
    const translatedTitle = title?.[language as keyof TranslatedString];
    return stringIncludes(translatedTitle, searchTerm);
};

export const filterOtherWorkByDescription = (
    { description }: OtherWorkResponse,
    searchTerm: string,
    language: string
): boolean => {
    const translatedDescription =
        description?.[language as keyof TranslatedString];
    return stringIncludes(translatedDescription, searchTerm);
};

export const filterOtherWorkByRol = (
    { rol }: OtherWorkResponse,
    searchTerm: string,
    language: string
): boolean => {
    const translatedRol = rol?.[language as keyof TranslatedString];
    return stringIncludes(translatedRol, searchTerm);
};

export const filterOtherWorkByDirection = (
    { direction }: OtherWorkResponse,
    searchTerm: string,
    language: string
): boolean => {
    const translatedDirection = direction?.[language as keyof TranslatedString];
    return stringIncludes(translatedDirection, searchTerm);
};

export const filterOtherWorkByProductor = (
    { productor }: OtherWorkResponse,
    searchTerm: string
): boolean => {
    if (!productor) {
        return false;
    }
    return stringIncludes(productor.name, searchTerm);
};
