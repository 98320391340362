import React, { useState } from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { ProjectCard } from '../../../components/back/card/ProjectCard';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { ProjectResponse } from '../../../core/models/Project';
import { SortDirection } from '../../../core/models/shared/SortDirection';
import { save } from '../../../core/services/firebase/resources.service';
import { useNavigate } from 'react-router-dom';
import { RESOURCES } from '../../../core/constants/resources';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../../../components/shared/button/AddButton';
import { BACK, PROJECT_DETAIL } from '../../../core/constants/routes';
import { DeleteModal } from '../../../components/shared/modal/DeleteModal';

export const Projects = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [modalDelete, setModalDelete] = useState<ProjectResponse | null>(
        null
    );

    const {
        data: projects,
        loading,
        mutation,
    } = useGetAll<ProjectResponse, ProjectResponse>({
        resource: RESOURCES.PROJECTS,
        mapper: (obj) => obj,
        filters: undefined,
        sort: {
            key: 'position',
            direction: SortDirection.DESC,
        },
    });

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (project: ProjectResponse) => () => {
        navigate(BACK.PROJECT_DETAIL(project.id));
    };

    const handleCardDelete = (project: ProjectResponse) => () => {
        setModalDelete(project);
    };

    const handleCardView = (project: ProjectResponse) => () => {
        navigate(PROJECT_DETAIL(project.hash));
    };

    const submitDelete = () => {
        if (modalDelete) {
            save(RESOURCES.PROJECTS, modalDelete.id, {
                ...modalDelete,
                deleted: true,
            });
            setModalDelete(null);
            mutation();
        }
    };

    return (
        <BackLayout>
            <AddButton floating onClick={() => navigate(BACK.PROJECT_CREATE)} />
            {projects.map((project) => (
                <ProjectCard
                    key={project.id}
                    project={project}
                    onClick={handleCardClick(project)}
                    onDelete={handleCardDelete(project)}
                    onView={handleCardView(project)}
                />
            ))}
            <DeleteModal
                show={!!modalDelete}
                title={t('pages.back.projects.modal.delete.title')}
                onDelete={submitDelete}
                onClose={() => setModalDelete(null)}
            >
                <p>{t('pages.back.projects.modal.delete.message')}</p>
            </DeleteModal>
        </BackLayout>
    );
};
