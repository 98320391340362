import React, { useState } from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { SortDirection } from '../../../core/models/shared/SortDirection';
import { save } from '../../../core/services/firebase/resources.service';
import { useNavigate, useParams } from 'react-router-dom';
import { RESOURCES } from '../../../core/constants/resources';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../../../components/shared/button/AddButton';
import { BACK } from '../../../core/constants/routes';
import { DeleteModal } from '../../../components/shared/modal/DeleteModal';
import { Menu, MenuResponse, MenuSubItemResponse } from 'core/models/Menu';
import { SubMenuCard } from 'components/back/card/SubMenuCard';
import { Row } from 'components/shared/Row';
import { Col } from 'components/shared/Col';
import { SideBarMenu } from 'components/layouts/main/SideBarMenu';
import { useGetOne } from 'core/hooks/firebase/useGetOne';
import { menuMapper } from 'core/mapper/menuMapper';
import { EmptySection } from 'components/shared/EmptySection';

export const SubMenu = () => {
    const navigate = useNavigate();
    const { id: menuId } = useParams();
    const { i18n, t } = useTranslation();

    const [modalDelete, setModalDelete] = useState<MenuSubItemResponse | null>(
        null
    );

    const {
        data: subItems,
        loading,
        mutation,
    } = useGetAll<MenuSubItemResponse, MenuSubItemResponse>({
        resource: `${RESOURCES.MENU}/${menuId}/subItems`,
        sort: {
            key: 'position',
            direction: SortDirection.ASC,
        },
    });

    const { data: menu } = useGetOne<Menu, MenuResponse>({
        resource: RESOURCES.MENU,
        id: menuId,
        mapper: menuMapper(i18n.language),
    });

    if (loading || !menuId || !menu) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (subItem: MenuSubItemResponse) => () => {
        navigate(BACK.SUB_MENU_DETAIL(menuId, subItem.id));
    };

    const handleCardDelete = (subItem: MenuSubItemResponse) => () => {
        setModalDelete(subItem);
    };

    const submitDelete = () => {
        if (modalDelete) {
            save(`${RESOURCES.MENU}/${menuId}/subItems`, modalDelete.id, {
                ...modalDelete,
                deleted: true,
            });
            setModalDelete(null);
            mutation();
        }
    };

    return (
        <BackLayout
            breadcrumbs={[
                { title: t('back.menu.menu'), url: BACK.MENU },
                { title: menu.title, url: BACK.MENU_DETAIL(menuId) },
                { title: t('back.menu.subItems') },
            ]}
        >
            <Row>
                <Col className="col-12 col-md-8">
                    <AddButton
                        floating
                        onClick={() => navigate(BACK.SUB_MENU_CREATE(menuId))}
                    />
                    {subItems.length === 0 && <EmptySection />}
                    {subItems.map((subItem) => (
                        <SubMenuCard
                            key={subItem.id}
                            subMenu={subItem}
                            onClick={handleCardClick(subItem)}
                            onDelete={handleCardDelete(subItem)}
                        />
                    ))}
                </Col>
                <Col className="col-12 col-md-4">
                    <SideBarMenu
                        breakPoint="sm"
                        className="m-3"
                        showLanguageSelector={false}
                        navigate={false}
                    />
                </Col>
            </Row>
            <DeleteModal
                show={!!modalDelete}
                title={t('pages.back.submenus.modal.delete.title')}
                onDelete={submitDelete}
                onClose={() => setModalDelete(null)}
            >
                <p>{t('pages.back.submenus.modal.delete.message')}</p>
            </DeleteModal>
        </BackLayout>
    );
};
