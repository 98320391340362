import { OtherWork, OtherWorkResponse } from '../models/OtherWork';
import { TranslatedString } from '../models/TranslatedString';
import { productorMapper } from './productorMapper';

/* prettier-ignore */
export const otherWorkMapper = (locale: string) => (otherWork: OtherWorkResponse): OtherWork => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        id: otherWork?.id ?? '',
        title: otherWork?.title?.[translatedStringLocale] ?? '',
        description: otherWork?.description?.[translatedStringLocale] ?? '',
        rol: otherWork?.rol?.[translatedStringLocale] ?? '',
        link: otherWork?.link ?? '',
        year: otherWork?.year ?? '',
        direction: otherWork?.direction?.[translatedStringLocale] ?? '',
        productor: otherWork?.productor ? productorMapper(otherWork.productor) : null,
        position: otherWork?.position ?? 0,
        visible: otherWork?.visible ?? false,
    };
};
