import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { GenderResponse } from '../../../core/models/Gender';
import { GenderDetailForm } from '../../../components/back/form/GenderDetailForm';
import { RESOURCES } from '../../../core/constants/resources';

export const GenderDetail = () => {
    const { id: genderId } = useParams();

    const { data: gender, loading } = useGetOne<GenderResponse, GenderResponse>(
        { resource: RESOURCES.CATEGORIES, id: genderId }
    );

    if (loading || !gender) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <GenderDetailForm gender={gender} />
        </BackLayout>
    );
};
