import React, { CSSProperties } from 'react';
import { Language } from '../../core/models/Language';
import clsx from 'clsx';

type Props = {
    languages: Language[];
    onChange: (code: string) => void;
    className?: string;
    optionClassName?: string;
    optionStyles?: CSSProperties;
};

export const LanguageSelector = ({
    languages,
    onChange,
    className,
    optionClassName,
    optionStyles,
}: Props) => {
    return (
        <div className={className}>
            {languages.map((language: Language) => (
                <span
                    key={language.code}
                    className={clsx('mx-1', optionClassName)}
                    role="button"
                    onClick={() => onChange(language.code)}
                    style={optionStyles}
                >
                    {language.name}
                </span>
            ))}
        </div>
    );
};
