import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { ProjectDetailForm } from '../../../components/back/form/ProjectDetailForm';
import { Type, TypeResponse } from '../../../core/models/Type';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { useTranslation } from 'react-i18next';
import { typeMapper } from '../../../core/mapper/typeMapper';
import { SubType, SubTypeResponse } from '../../../core/models/SubType';
import { subTypeMapper } from '../../../core/mapper/subTypeMapper';
import { SubSubType, SubSubTypeResponse } from '../../../core/models/SubSubType';
import { subSubTypeMapper } from '../../../core/mapper/subSubTypeMapper';
import { Gender, GenderResponse } from '../../../core/models/Gender';
import { genderMapper } from '../../../core/mapper/genderMapper';
import { RESOURCES } from '../../../core/constants/resources';
import { Award, AwardResponse } from '../../../core/models/Award';
import { awardMapper } from '../../../core/mapper/awardMapper';

export const ProjectCreate = () => {
    const { i18n } = useTranslation();

    const { data: types, loading: loadingTypes } = useGetAll<
        Type,
        TypeResponse
    >({ resource: RESOURCES.TYPES, mapper: typeMapper(i18n.language) });

    const { data: subTypes, loading: loadingSubTypes } = useGetAll<
        SubType,
        SubTypeResponse
    >({ resource: RESOURCES.SUBTYPES, mapper: subTypeMapper(i18n.language) });

    const { data: subSubTypes, loading: loadingSubSubTypes } = useGetAll<
        SubSubType,
        SubSubTypeResponse
    >({
        resource: RESOURCES.SUBSUBTYPES,
        mapper: subSubTypeMapper(i18n.language),
    });

    const { data: genders, loading: loadingGenders } = useGetAll<
        Gender,
        GenderResponse
    >({ resource: RESOURCES.CATEGORIES, mapper: genderMapper(i18n.language) });

    const { data: awards, loading: loadingAwards } = useGetAll<
        Award,
        AwardResponse
    >({ resource: RESOURCES.AWARDS, mapper: awardMapper });

    if (
        loadingTypes ||
        loadingSubTypes ||
        loadingSubSubTypes ||
        loadingGenders ||
        loadingAwards
    ) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <ProjectDetailForm
                types={types}
                subTypes={subTypes}
                subSubTypes={subSubTypes}
                genders={genders}
                awards={awards}
            />
        </BackLayout>
    );
};
