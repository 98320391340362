import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { MarginType, PaddingType } from '../type/BootstrapType';

type Props = {
    onLogout: () => void;
    m?: MarginType;
    p?: PaddingType;
};

export const LogoutButton = ({ onLogout, m, p }: Props) => {
    const { t } = useTranslation();

    return (
        <Button onClick={onLogout} m={m} p={p}>
            {t('back.logout')}
        </Button>
    );
};
