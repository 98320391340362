import React from 'react';
import { GenderResponse } from '../../../core/models/Gender';
import { useTranslation } from 'react-i18next';
import { TranslatedString } from '../../../core/models/TranslatedString';
import { DeleteButton } from '../../shared/button/DeleteButton';
import { Card } from '../../shared/Card';
import { Badge } from '../../shared/Badge';

type Props = {
    gender: GenderResponse;
    onClick: () => void;
    onDelete: () => void;
};

export const GenderCard = ({
    gender: { code, text },
    onClick,
    onDelete,
}: Props) => {
    const { i18n } = useTranslation();

    return (
        <Card
            onClick={onClick}
            actions={<DeleteButton onClick={onDelete} />}
            m={2}
            p={2}
        >
            {code}
            <div className="d-flex flex-column">
                {i18n.languages.map((language) => (
                    <span key={language}>
                        <Badge className="mx-1">{language}</Badge>
                        {text?.[language as keyof TranslatedString]}
                    </span>
                ))}
            </div>
        </Card>
    );
};
