import React, { useState } from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { TypeResponse } from '../../../core/models/Type';
import { TypeCard } from '../../../components/back/card/TypeCard';
import { save } from '../../../core/services/firebase/resources.service';
import { useNavigate } from 'react-router-dom';
import { RESOURCES } from '../../../core/constants/resources';
import { AddButton } from '../../../components/shared/button/AddButton';
import { useTranslation } from 'react-i18next';
import { BACK } from '../../../core/constants/routes';
import { DeleteModal } from '../../../components/shared/modal/DeleteModal';

export const Types = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [modalDelete, setModalDelete] = useState<TypeResponse | null>(null);

    const {
        data: types,
        loading,
        mutation,
    } = useGetAll<TypeResponse, TypeResponse>({ resource: RESOURCES.TYPES });

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (type: TypeResponse) => () => {
        navigate(BACK.TYPE_DETAIL(type.id));
    };

    const handleCardDelete = (type: TypeResponse) => () => {
        setModalDelete(type);
    };

    const submitDelete = () => {
        if (modalDelete) {
            save(RESOURCES.TYPES, modalDelete.id, {
                ...modalDelete,
                deleted: true,
            });
            setModalDelete(null);
            mutation();
        }
    };

    return (
        <BackLayout>
            <AddButton floating onClick={() => navigate(BACK.TYPES_CREATE)} />
            {types.map((type) => (
                <TypeCard
                    key={type.id}
                    type={type}
                    onClick={handleCardClick(type)}
                    onDelete={handleCardDelete(type)}
                />
            ))}
            <DeleteModal
                show={!!modalDelete}
                title={t('pages.back.types.modal.delete.title')}
                onDelete={submitDelete}
                onClose={() => setModalDelete(null)}
            >
                <p>{t('pages.back.types.modal.delete.message')}</p>
            </DeleteModal>
        </BackLayout>
    );
};
