import { useState, useEffect } from 'react';

type SizeType = {
    code: string;
    level: number;
};

const SIZES = {
    xs: { code: 'xs', level: 1 } as SizeType,
    sm: { code: 'sm', level: 2 } as SizeType,
    md: { code: 'md', level: 3 } as SizeType,
    lg: { code: 'lg', level: 4 } as SizeType,
    xl: { code: 'xl', level: 5 } as SizeType,
};

const useBootstrapScreenSize = () => {
    const getSize = () => {
        if (window.innerWidth < 576) {
            return SIZES.xs;
        } else if (window.innerWidth >= 576 && window.innerWidth < 768) {
            return SIZES.sm;
        } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
            return SIZES.md;
        } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
            return SIZES.lg;
        } else {
            return SIZES.xl;
        }
    };

    const [size, setSize] = useState<SizeType>(getSize());

    const handleResize = () => {
        setSize(getSize());
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return size;
};

export default useBootstrapScreenSize;
