import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { ProjectDetail } from './pages/ProjectDetail';
import { Login } from './pages/auth/Login';
import { NotFound } from './components/shared/NotFound';
import { Spinner } from './components/shared/Spinner';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ProtectedRoute } from './components/shared/ProtectedRoute';
import { Projects as BackProjects } from './pages/back/project/Projects';
import { OtherWorks as BackOtherWorks } from './pages/back/otherWork/OtherWorks';
import { Types as BackTypes } from './pages/back/type/Types';
import { Languages as BackLanguages } from './pages/back/language/Languages';
import { Genders as BackGenders } from './pages/back/gender/Genders';
import { Awards as BackAwards } from './pages/back/award/Awards';
import { SubTypes as BackSubTypes } from './pages/back/subType/SubTypes';
import { SubSubTypes as BackSubSubTypes } from './pages/back/subSubType/SubSubTypes';
import { ProjectDetail as BackProjectDetail } from './pages/back/project/ProjectDetail';
import { OtherWorkDetail as BackOtherWorkDetail } from './pages/back/otherWork/OtherWorkDetail';
import { TypeDetail as BackTypeDetail } from './pages/back/type/TypeDetail';
import { LanguageDetail as BackLanguageDetail } from './pages/back/language/LanguageDetail';
import { GenderDetail as BackGenderDetail } from './pages/back/gender/GenderDetail';
import { SubTypeDetail as BackSubTypeDetail } from './pages/back/subType/SubTypeDetail';
import { SubSubTypeDetail as BackSubSubTypeDetail } from './pages/back/subSubType/SubSubTypeDetail';
import { AwardDetail as BackAwardDetail } from './pages/back/award/AwardDetail';
import { AwardCreate as BackAwardCreate } from './pages/back/award/AwardCreate';
import { ProjectCreate as BackProjectCreate } from './pages/back/project/ProjectCreate';
import { TypeCreate as BackTypeCreate } from './pages/back/type/TypeCreate';
import { SubTypeCreate as BackSubTypeCreate } from './pages/back/subType/SubTypeCreate';
import { SubSubTypeCreate as BackSubSubTypeCreate } from './pages/back/subSubType/SubSubTypeCreate';
import { GenderCreate as BackGenderCreate } from './pages/back/gender/GenderCreate';
import { OtherWorkCreate as BackOtherWorkCreate } from './pages/back/otherWork/OtherWorkCreate';
import { TestA as BackTestA } from './pages/back/TestA';
import { TestB as BackTestB } from './pages/back/TestB';
import { General as BackGeneral } from './pages/back/General';
import { Menu as BackMenu } from './pages/back/menu/Menu';
import { MenuCreate as BackMenuCreate } from './pages/back/menu/MenuCreate';
import { MenuDetail as BackMenuDetail } from './pages/back/menu/MenuDetail';
import { SubMenu as BackSubMenu } from './pages/back/subMenu/SubMenu';
import { SubMenuCreate as BackSubMenuCreate } from './pages/back/subMenu/SubMenuCreate';
import { SubMenuDetail as BackSubMenuDetail } from './pages/back/subMenu/SubMenuDetail';
import {
    ABOUT,
    BACK,
    BACK_ROOT,
    CONTACT,
    LOGIN,
    LOGOUT,
    OTHER_WORK,
    PROJECT_DETAIL,
    ROOT,
} from './core/constants/routes';
import { Logout } from './pages/auth/Logout';
import { About } from './pages/About';
import { OtherWorks } from './pages/OtherWorks';
import { Contact } from './pages/Contact';
import { HelmetProps } from './components/shared/HelmetProps';
import { useGetOne } from 'core/hooks/firebase/useGetOne';
import { Meta } from 'core/models/shared/Meta';
import { RESOURCES } from 'core/constants/resources';

export const App = () => {
    const [user, loading] = useAuthState(auth);

    const { data: meta, loading: loadingMeta } = useGetOne<Meta, Meta>({
        resource: RESOURCES.ROOT,
        id: 'meta',
    });

    const { data: fontFamilyPrimary, loading: loadingFontFamilyPrimary } = useGetOne<
        string,
        string
    >({
        resource: RESOURCES.GENERAL,
        id: 'fontFamilyPrimary',
    });

    useEffect(() => {
        if (fontFamilyPrimary) {
            import(`./styles/font-${fontFamilyPrimary}.css`);
        }
    }, [fontFamilyPrimary]);

    if (loading || loadingMeta || loadingFontFamilyPrimary || !meta) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const protectedRoutes = [
        {
            path: BACK.PROJECTS,
            component: <BackProjects />,
        },
        {
            path: BACK.PROJECT_CREATE,
            component: <BackProjectCreate />,
        },
        {
            path: BACK.PROJECT_DETAIL(':id'),
            component: <BackProjectDetail />,
        },
        {
            path: BACK.TYPES,
            component: <BackTypes />,
        },
        {
            path: BACK.TYPES_CREATE,
            component: <BackTypeCreate />,
        },
        {
            path: BACK.TYPE_DETAIL(':id'),
            component: <BackTypeDetail />,
        },
        {
            path: BACK.LANGUAGES,
            component: <BackLanguages />,
        },
        {
            path: BACK.LANGUAGE_DETAIL(':id'),
            component: <BackLanguageDetail />,
        },
        {
            path: BACK.GENDERS,
            component: <BackGenders />,
        },
        {
            path: BACK.GENDER_CREATE,
            component: <BackGenderCreate />,
        },
        {
            path: BACK.GENDER_DETAIL(':id'),
            component: <BackGenderDetail />,
        },
        {
            path: BACK.SUBTYPES,
            component: <BackSubTypes />,
        },
        {
            path: BACK.SUBTYPE_CREATE,
            component: <BackSubTypeCreate />,
        },
        {
            path: BACK.SUBTYPE_DETAIL(':id'),
            component: <BackSubTypeDetail />,
        },
        {
            path: BACK.SUBSUBTYPES,
            component: <BackSubSubTypes />,
        },
        {
            path: BACK.SUBSUBTYPE_CREATE,
            component: <BackSubSubTypeCreate />,
        },
        {
            path: BACK.SUBSUBTYPE_DETAIL(':id'),
            component: <BackSubSubTypeDetail />,
        },
        {
            path: BACK.OTHER_WORKS,
            component: <BackOtherWorks />,
        },
        {
            path: BACK.OTHER_WORK_CREATE,
            component: <BackOtherWorkCreate />,
        },
        {
            path: BACK.OTHER_WORK_DETAIL(':id'),
            component: <BackOtherWorkDetail />,
        },
        {
            path: BACK.AWARDS,
            component: <BackAwards />,
        },
        {
            path: BACK.AWARD_CREATE,
            component: <BackAwardCreate />,
        },
        {
            path: BACK.AWARD_DETAIL(':id'),
            component: <BackAwardDetail />,
        },
        {
            path: BACK.GENERAL,
            component: <BackGeneral />,
        },
        {
            path: BACK.MENU,
            component: <BackMenu />,
        },
        {
            path: BACK.MENU_CREATE,
            component: <BackMenuCreate />,
        },
        {
            path: BACK.MENU_DETAIL(':id'),
            component: <BackMenuDetail />,
        },
        {
            path: BACK.SUB_MENU(':id'),
            component: <BackSubMenu />,
        },
        {
            path: BACK.SUB_MENU_CREATE(':id'),
            component: <BackSubMenuCreate />,
        },
        {
            path: BACK.SUB_MENU_DETAIL(':id', ':subItemId'),
            component: <BackSubMenuDetail />,
        },
        {
            path: BACK.TEST_A,
            component: <BackTestA />,
        },
        {
            path: BACK.TEST_B,
            component: <BackTestB />,
        },
    ];

    return (
        <>
            <HelmetProps meta={meta} />
            <Routes>
                <Route index element={<Home />} />
                <Route
                    path={LOGIN}
                    element={<Login navigateTo={BACK.PROJECTS} />}
                />
                <Route path={LOGOUT} element={<Logout navigateTo={LOGIN} />} />
                <Route
                    path={BACK_ROOT}
                    element={<Navigate to={BACK.PROJECTS} />}
                />
                <Route path={OTHER_WORK} element={<OtherWorks />} />
                <Route path={ABOUT} element={<About />} />
                <Route path={CONTACT} element={<Contact />} />
                <Route
                    path={PROJECT_DETAIL(':id')}
                    element={<ProjectDetail />}
                />
                <Route path="/:type" element={<Home />} />
                <Route path="/:type/:subType" element={<Home />} />
                {protectedRoutes.map(({ path, component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute user={user} defaultRoute={ROOT}>
                                {component}
                            </ProtectedRoute>
                        }
                    />
                ))}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};
