import React, { useState } from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { SortDirection } from '../../../core/models/shared/SortDirection';
import { save } from '../../../core/services/firebase/resources.service';
import { useNavigate } from 'react-router-dom';
import { RESOURCES } from '../../../core/constants/resources';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../../../components/shared/button/AddButton';
import { BACK } from '../../../core/constants/routes';
import { DeleteModal } from '../../../components/shared/modal/DeleteModal';
import { MenuResponse } from 'core/models/Menu';
import { MenuCard } from 'components/back/card/MenuCard';
import { Row } from 'components/shared/Row';
import { Col } from 'components/shared/Col';
import { SideBarMenu } from 'components/layouts/main/SideBarMenu';

export const Menu = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [modalDelete, setModalDelete] = useState<MenuResponse | null>(null);

    const {
        data: menuItems,
        loading,
        mutation,
    } = useGetAll<MenuResponse, MenuResponse>({
        resource: RESOURCES.MENU,
        sort: {
            key: 'position',
            direction: SortDirection.ASC,
        },
        mapper: (obj: MenuResponse) => ({
            ...obj,
            subItems: obj.subItems ? Object.values(obj.subItems) : [],
        }),
    });

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (menu: MenuResponse) => () => {
        navigate(BACK.MENU_DETAIL(menu.id));
    };

    const handleCardDelete = (menu: MenuResponse) => () => {
        setModalDelete(menu);
    };

    const submitDelete = () => {
        if (modalDelete) {
            save(RESOURCES.MENU, modalDelete.id, {
                ...modalDelete,
                deleted: true,
            });
            setModalDelete(null);
            mutation();
        }
    };

    return (
        <BackLayout>
            <Row>
                <Col className="col-12 col-md-8">
                    <AddButton
                        floating
                        onClick={() => navigate(BACK.MENU_CREATE)}
                    />
                    {menuItems.map((item) => (
                        <MenuCard
                            key={item.id}
                            menu={item}
                            onClick={handleCardClick(item)}
                            onDelete={handleCardDelete(item)}
                        />
                    ))}
                </Col>
                <Col className="col-12 col-md-4">
                    <SideBarMenu
                        breakPoint="sm"
                        className="m-3"
                        showLanguageSelector={false}
                        navigate={false}
                    />
                </Col>
            </Row>
            <DeleteModal
                show={!!modalDelete}
                title={t('pages.back.menus.modal.delete.title')}
                onDelete={submitDelete}
                onClose={() => setModalDelete(null)}
            >
                <p>{t('pages.back.menus.modal.delete.message')}</p>
            </DeleteModal>
        </BackLayout>
    );
};
