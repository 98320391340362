import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { MarginType, PaddingType } from './type/BootstrapType';

type Props = {
    children: ReactNode;
    className?: string;
    m?: MarginType;
    p?: PaddingType;
};

export const Row = ({ children, className, m = 0, p = 0 }: Props) => {
    return (
        <div className={clsx('row', className, `m-${m}`, `p-${p}`)}>
            {children}
        </div>
    );
};
