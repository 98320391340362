import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { RESOURCES } from '../../../core/constants/resources';
import { AwardResponse } from '../../../core/models/Award';
import { AwardDetailForm } from '../../../components/back/form/AwardDetailForm';

export const AwardDetail = () => {
    const { id: awardId } = useParams();

    const { data: award, loading } = useGetOne<AwardResponse, AwardResponse>({
        resource: RESOURCES.AWARDS,
        id: awardId,
    });

    if (loading || !award) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <AwardDetailForm award={award} />
        </BackLayout>
    );
};
