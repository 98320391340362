import React, { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
    children: ReactNode;
    methods: UseFormReturn<T>;
    className?: string;
    onSubmit: (data: T) => void;
};

export const Form = <T extends FieldValues>({
    children,
    methods,
    className,
    onSubmit,
}: Props<T>) => {
    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className={className}
            >
                {children}
            </form>
        </FormProvider>
    );
};
