import { useEffect, useState } from 'react';
import { getOne } from 'core/services/firebase/resources.service';

type Props<T, R> = {
    resource: string;
    id: string | undefined;
    mapper?: (obj: R) => T;
};

export const useGetOne = <T, R>({ resource, id, mapper }: Props<T, R>) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<T | null | undefined>(null);

    const mutation = async () => {
        setLoading(true);
        const response = await getOne(resource, id);
        if (mapper) {
            setData(mapper(response as R));
        } else {
            setData(response as T);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (id) {
            mutation();
        }
    }, [resource, id]);

    useEffect(() => {
        setError(data == undefined && data != null);
    }, [data]);

    return { data, loading, mutation, error };
};
