import React, { ReactNode, useEffect } from 'react';
import { useState } from 'react';
import { Row } from '../../components/shared/Row';
import { Box } from '../../components/shared/Box';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoginFormSubmit } from '../../core/models/shared/LoginFormSubmit';
import { auth, logInWithEmailAndPassword } from '../../firebase';
import { Input } from '../../components/shared/form/Input';
import { Button } from '../../components/shared/button/Button';
import { useForm } from 'react-hook-form';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Col } from '../../components/shared/Col';
import { Form } from 'components/shared/form/Form';
import { H1 } from 'components/shared/H';

type Props = {
    navigateTo: string;
    children?: ReactNode;
};

export const Login = ({ navigateTo, children }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [user, loading] = useAuthState(auth);
    const [hasError, setHasError] = useState<boolean>(false);

    const methods = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const handleLogin = async ({ email, password }: LoginFormSubmit) => {
        const response = await logInWithEmailAndPassword(email, password);
        setHasError(!response);
    };

    useEffect(() => {
        if (loading) return;
        if (user) navigate(navigateTo);
    }, [user, loading]);

    return (
        <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col className="col-12 col-md-5">
                <Box className="text-center p-3 m-3">
                    <H1 className="mb-2">{t('auth.login.title')}</H1>
                    <Form methods={methods} onSubmit={handleLogin}>
                        <Input
                            name="email"
                            label={t('auth.login.fields.email')}
                            required
                        />
                        <Input
                            type="password"
                            name="password"
                            label={t('auth.login.fields.password')}
                            required
                            passwordReveal
                        />
                        {hasError && (
                            <div className="mb-2 text-danger">
                                {t('auth.login.error')}
                            </div>
                        )}
                        <div className="d-flex justify-content-center pt-2">
                            <Button submit fullWidth>
                                {t('auth.login.buttons.logIn')}
                            </Button>
                        </div>
                    </Form>
                    {children}
                </Box>
            </Col>
        </Row>
    );
};
