import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Meta } from '../../core/models/shared/Meta';

type Props = {
    meta: Meta;
};

export const HelmetProps = ({ meta }: Props) => {
    const location = useLocation();

    return (
        <Helmet>
            <title>{meta?.title}</title>
            <link rel="icon" href={meta?.favicon} />
            <meta name="description" content={meta?.description} />
            <meta name="keywords" content={meta?.keywords} />
            <meta name="author" content={meta?.author} />
            <meta name="theme-color" content={meta?.themeColor} />
            <meta property="og:title" content={meta?.title} />
            <meta property="og:description" content={meta?.description} />
            <meta property="og:url" content={location.pathname} />
            <meta name="twitter:title" content={meta?.title} />
            <meta name="twitter:description" content={meta?.description} />
        </Helmet>
    );
};
