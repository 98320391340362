import React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { FormLabel } from './FormLabel';
import { Checkbox } from './Checkbox';
import { push, remove } from '../../../core/utils/collection-util';
import { useTranslation } from 'react-i18next';
import { MarginType, PaddingType } from '../type/BootstrapType';
import { ErrorLabel } from './ErrorLabel';

type Props = {
    label?: string;
    name: string;
    options: { key: string; name: string }[];
    className?: string;
    multiple?: boolean;
    emptyOption?: boolean;
    placeholder?: string;
    required?: boolean;
    m?: MarginType;
    p?: PaddingType;
    mt?: MarginType;
    mb?: MarginType;
    mx?: MarginType;
    my?: MarginType;
    disabled?: boolean;
};

export const Select = ({
    label,
    name,
    className,
    options,
    multiple = false,
    emptyOption = false,
    placeholder = '',
    required = false,
    m = 0,
    p = 0,
    mt,
    mb = 2,
    mx,
    my,
    disabled = false,
}: Props) => {
    const { t } = useTranslation();

    const {
        register,
        watch,
        getValues,
        setValue,
        formState: { errors },
    } = useFormContext();

    const requiredObject = {
        required: t('validation.required'),
    };

    const registerOptions = {
        ...(required ? requiredObject : {}),
    };

    const registration = register(name, registerOptions);

    const hasError = !!errors[name];

    return (
        <div
            className={clsx(className, `m-${m}`, `p-${p}`, {
                [`mt-${mt}`]: mt !== undefined,
                [`mb-${mb}`]: mb !== undefined,
                [`mx-${mx}`]: mx !== undefined,
                [`my-${my}`]: my !== undefined,
            })}
        >
            {label && <FormLabel label={label} />}
            {!multiple && (
                <select
                    className={clsx('form-select pointer', {
                        'border border-danger': hasError,
                        disabled: disabled,
                    })}
                    {...registration}
                    defaultValue=""
                    disabled={disabled}
                >
                    {emptyOption ? (
                        <option value="" disabled>
                            {placeholder}
                        </option>
                    ) : null}
                    {options.map((option) => (
                        <option key={option.key} value={option.key}>
                            {option.name}
                        </option>
                    ))}
                </select>
            )}
            {multiple && (
                <>
                    {options.map((option) => (
                        <Checkbox
                            key={option.key}
                            name={option.key}
                            label={option.name}
                            labelPosition="end"
                            doRegister={false}
                            checked={watch(name)?.includes(option.key)}
                            onChange={(value: boolean) => {
                                const newArray = getValues(name) ?? [];
                                if (value) {
                                    push(newArray, option.key);
                                } else {
                                    remove(newArray, option.key);
                                }
                                setValue(name, newArray);
                            }}
                        />
                    ))}
                </>
            )}
            {name && <ErrorLabel name={name} />}
        </div>
    );
};
