import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import { useGetOne } from '../../../core/hooks/firebase/useGetOne';
import { TypeResponse } from '../../../core/models/Type';
import { TypeDetailForm } from '../../../components/back/form/TypeDetailForm';
import { RESOURCES } from 'core/constants/resources';

export const TypeDetail = () => {
    const { id: typeId } = useParams();

    const { data: type, loading } = useGetOne<TypeResponse, TypeResponse>({
        resource: RESOURCES.TYPES,
        id: typeId,
    });

    if (loading || !type) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    return (
        <BackLayout>
            <TypeDetailForm type={type} />
        </BackLayout>
    );
};
