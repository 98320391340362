import React from 'react';
import clsx from 'clsx';
import { Button } from './Button';
import { IconBootstrap } from '../Icon';
import { MarginType, PaddingType } from '../type/BootstrapType';

type Props = {
    children?: string;
    className?: string;
    disabled?: boolean;
    floating?: boolean;
    submit?: boolean;
    onClick?: () => void;
    m?: MarginType;
    p?: PaddingType;
};

export const SaveButton = ({
    children,
    className,
    disabled = false,
    floating = false,
    submit = false,
    onClick,
    m,
    p,
}: Props) => {
    return (
        <Button
            className={clsx('px-2 py-0', className, {
                'position-fixed bottom-0 end-0 me-3 mb-2 z-1': floating,
            })}
            disabled={disabled}
            onClick={onClick}
            submit={submit}
            m={m}
            p={p}
        >
            {children ? (
                <>{children}</>
            ) : (
                <IconBootstrap name="floppy" color="white" size="xl" />
            )}
        </Button>
    );
};
