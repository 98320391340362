import React, { useState } from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { Spinner } from '../../../components/shared/Spinner';
import { GenderCard } from '../../../components/back/card/GenderCard';
import { useGetAll } from '../../../core/hooks/firebase/useGetAll';
import { GenderResponse } from '../../../core/models/Gender';
import { RESOURCES } from '../../../core/constants/resources';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../../../components/shared/button/AddButton';
import { BACK } from '../../../core/constants/routes';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from '../../../components/shared/modal/DeleteModal';
import { save } from '../../../core/services/firebase/resources.service';

export const Genders = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [modalDelete, setModalDelete] = useState<GenderResponse | null>(null);

    const {
        data: genders,
        loading,
        mutation,
    } = useGetAll<GenderResponse, GenderResponse>({
        resource: RESOURCES.CATEGORIES,
    });

    if (loading) {
        return (
            <div className="vh-100">
                <Spinner />
            </div>
        );
    }

    const handleCardClick = (gender: GenderResponse) => () => {
        navigate(BACK.GENDER_DETAIL(gender.id));
    };

    const handleCardDelete = (gender: GenderResponse) => () => {
        setModalDelete(gender);
    };

    const submitDelete = () => {
        if (modalDelete) {
            save(RESOURCES.CATEGORIES, modalDelete.id, {
                ...modalDelete,
                deleted: true,
            });
            setModalDelete(null);
            mutation();
        }
    };

    return (
        <BackLayout>
            <AddButton floating onClick={() => navigate(BACK.GENDER_CREATE)} />
            {genders.map((gender) => (
                <GenderCard
                    key={gender.code}
                    gender={gender}
                    onClick={handleCardClick(gender)}
                    onDelete={handleCardDelete(gender)}
                />
            ))}
            <DeleteModal
                show={!!modalDelete}
                title={t('pages.back.genders.modal.delete.title')}
                onDelete={submitDelete}
                onClose={() => setModalDelete(null)}
            >
                <p>{t('pages.back.genders.modal.delete.message')}</p>
            </DeleteModal>
        </BackLayout>
    );
};
