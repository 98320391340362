import { TranslatedString } from '../models/TranslatedString';
import { Type, TypeResponse } from '../models/Type';

// prettier-ignore
export const typeMapper = (locale: string) => (type: TypeResponse): Type => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        id: type.id,
        code: type?.code ?? '',
        text: type?.text?.[translatedStringLocale] ?? '',
    };
};

export const convertTypeToSelectOption = (
    type: Type
): { key: string; name: string } => ({ key: type.code, name: type.text });
