import React from 'react';
import { H1 } from './H';
import { P } from './P';

export const NotFound = () => {
    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <H1 className="display-1 fw-bold">404</H1>
                <P className="fs-3">
                    <span className="text-danger">Opps!</span> Page not found.
                </P>
                <P className="lead">The page you’re looking for doesn’t exist.</P>
            </div>
        </div>
    );
};
