import React, { useEffect } from 'react';
import { Project, ProjectResponse } from '../core/models/Project';
import { MainLayout } from '../components/layouts/main/MainLayout';
import { useGetAll } from '../core/hooks/firebase/useGetAll';
import { projectMapper } from '../core/mapper/projectMapper';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '../core/models/shared/SortDirection';
import { Type, TypeResponse } from '../core/models/Type';
import { typeMapper } from '../core/mapper/typeMapper';
import { SubType, SubTypeResponse } from '../core/models/SubType';
import { subTypeMapper } from '../core/mapper/subTypeMapper';
import { SubSubType, SubSubTypeResponse } from '../core/models/SubSubType';
import { subSubTypeMapper } from '../core/mapper/subSubTypeMapper';
import { RESOURCES } from '../core/constants/resources';
import { useParams } from 'react-router-dom';
import { Row } from 'components/shared/Row';
import { Col } from 'components/shared/Col';
import { ProjectCardv2 } from 'components/projects/ProjectCardv2';
import { divideByFn } from 'core/utils/collection-util';
import useBootstrapScreenSize from 'core/hooks/bootstrap/useBootstrapScreenSize';
import { useProjectsFilterStore } from 'core/store/projectsFilter';
import { useDebounce } from '@uidotdev/usehooks';
import { filterProject } from 'core/utils/project-util';
import { Gender, GenderResponse } from 'core/models/Gender';
import { genderMapper } from 'core/mapper/genderMapper';
import { TEAM_ROLES } from 'core/constants/teamRoles';

type Props = {
    displayHeader?: boolean;
};

export const Home = ({ displayHeader = true }: Props) => {
    const { t, i18n } = useTranslation();
    const { type, subType } = useParams();

    const { data: types, mutation: typesMutation } = useGetAll<
        Type,
        TypeResponse
    >({ resource: RESOURCES.TYPES, mapper: typeMapper(i18n.language) });

    const { data: subTypes, mutation: subTypesMutation } = useGetAll<
        SubType,
        SubTypeResponse
    >({ resource: RESOURCES.SUBTYPES, mapper: subTypeMapper(i18n.language) });

    const { data: subSubTypes, mutation: subSubTypesMutation } = useGetAll<
        SubSubType,
        SubSubTypeResponse
    >({
        resource: RESOURCES.SUBSUBTYPES,
        mapper: subSubTypeMapper(i18n.language),
    });

    const { data: genders } = useGetAll<Gender, GenderResponse>({
        resource: RESOURCES.CATEGORIES,
        mapper: genderMapper(i18n.language),
    });

    const searchTerm = useProjectsFilterStore((state) => state.search);
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    const teamTranslations = TEAM_ROLES.reduce(
        (acc, curr) => ({
            ...acc,
            [curr]: t(`pages.project.fields.team.${curr}`),
        }),
        {}
    );

    const { data: projects, mutation: projectsMutation } = useGetAll<
        Project,
        ProjectResponse
    >({
        resource: RESOURCES.PROJECTS,
        mapper: projectMapper(i18n.language)(types, subTypes, subSubTypes),
        filters: {
            visible: true,
            ...(type ? { type } : {}),
            ...(subType ? { subType } : {}),
            ...(debouncedSearchTerm
                ? { searchFilter: filterProject(debouncedSearchTerm, i18n.language, types, subSubTypes, genders, subSubTypes, teamTranslations) }
                : {}),
        },
        sort: { key: 'position', direction: SortDirection.DESC },
    });

    useEffect(() => {
        typesMutation();
        subTypesMutation();
        subSubTypesMutation();
    }, [i18n.language]);

    useEffect(() => {
        projectsMutation();
    }, [types, subTypes, subSubTypes, debouncedSearchTerm]);

    const { level } = useBootstrapScreenSize();

    const getGroups = () => {
        if (level === 1) {
            return [() => true];
        }
        if (level === 2) {
            return [
                (item: Project, index: number) => (index + 2) % 2 === 0,
                (item: Project, index: number) => (index + 1) % 2 === 0,
            ];
        }
        return [
            (item: Project, index: number) => (index + 3) % 3 === 0,
            (item: Project, index: number) => (index + 2) % 3 === 0,
            (item: Project, index: number) => (index + 1) % 3 === 0,
        ];
    };

    const groups = divideByFn<Project>(projects, getGroups());

    return (
        <MainLayout displayHeader={displayHeader}>
            <Row>
                {groups.map((group, index) => (
                    <Col key={index}>
                        {group.map((project: Project) => (
                            <ProjectCardv2
                                key={project.hash}
                                project={project}
                            />
                        ))}
                    </Col>
                ))}
            </Row>
        </MainLayout>
    );
};
