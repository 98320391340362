import React, { useState } from 'react';
import { SideBarMenu } from './SideBarMenu';
import { IconBootstrap } from '../../shared/Icon';
import ProjectSearchInput from 'components/projects/ProjectSearchInput';

type Props = {
    breakPoint: 'sm' | 'md' | 'lg' | 'xl';
    height: string;
    displaySearchInput?: boolean;
};

export const ResponsiveMenu = ({
    breakPoint,
    height,
    displaySearchInput = false,
}: Props) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
        <>
            <div className="d-flex">
                {displaySearchInput && (
                    <ProjectSearchInput />
                )}
                <IconBootstrap
                    name="list"
                    color="secondary"
                    className="d-flex align-items-center"
                    onClick={() => setIsOpened(!isOpened)}
                />
            </div>
            {isOpened && (
                <SideBarMenu
                    breakPoint={breakPoint}
                    className="fixed-top text-center"
                    height={height}
                    defaultOpened={false}
                    showBackendButton
                />
            )}
        </>
    );
};
