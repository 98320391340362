import { ProjectResponse, Team } from 'core/models/Project';
import { stringIncludes } from './string-util';
import { TranslatedString } from 'core/models/TranslatedString';
import { Type } from 'core/models/Type';
import { SubType } from 'core/models/SubType';
import { Gender } from 'core/models/Gender';
import { SubSubType } from 'core/models/SubSubType';

export const filterProject =
    (
        searchTerm: string,
        language: string,
        types: Type[],
        subTypes: SubType[],
        genders: Gender[],
        subSubTypes: SubSubType[],
        teamTranslations: Record<string, string>
    ) => (project: ProjectResponse): boolean => {
        const shouldDisplayByTitle = filterProjectByTitle(
            project,
            searchTerm,
            language
        );
        const shouldDisplayByDescription = filterProjectByDescription(
            project,
            searchTerm,
            language
        );
        const shouldDisplayByType = filterProjectByType(
            project,
            searchTerm,
            types
        );
        const shouldDisplayBySubType = filterProjectBySubType(
            project,
            searchTerm,
            subTypes
        );
        const shouldDisplayBySubSubType = filterProjectBySubSubType(
            project,
            searchTerm,
            subSubTypes
        );
        const shouldDisplayByTeam = filterProjectByTeam(project, searchTerm);
        const shouldDisplayByTeamKeyAppers = filterProjectByTeamKeyAppers(
            project,
            searchTerm,
            teamTranslations
        );
        const shouldDisplayByGender = filterProjectByGender(
            project,
            searchTerm,
            genders
        );
        const shouldDIsplay =
            shouldDisplayByTitle ||
            shouldDisplayByDescription ||
            shouldDisplayByType ||
            shouldDisplayBySubType ||
            shouldDisplayBySubSubType ||
            shouldDisplayByTeam ||
            shouldDisplayByTeamKeyAppers ||
            shouldDisplayByGender;
        return shouldDIsplay;
    };

export const filterProjectByTitle = (
    { title }: ProjectResponse,
    searchTerm: string,
    language: string
): boolean => {
    const translatedTitle = title?.[language as keyof TranslatedString];
    return stringIncludes(translatedTitle, searchTerm);
};

export const filterProjectByDescription = (
    { description }: ProjectResponse,
    searchTerm: string,
    language: string
): boolean => {
    const translatedDescription =
        description?.[language as keyof TranslatedString];
    return stringIncludes(translatedDescription, searchTerm);
};

export const filterProjectByType = (
    project: ProjectResponse,
    searchTerm: string,
    types: Type[]
): boolean => {
    const foundedTypes = types.filter((type) => project.type == type.code);
    if (foundedTypes.length <= 0) {
        return false;
    }
    const foundedType = foundedTypes[0];
    return stringIncludes(foundedType.text, searchTerm);
};

export const filterProjectBySubType = (
    project: ProjectResponse,
    searchTerm: string,
    subTypes: SubType[]
): boolean => {
    const foundedSubTypes = subTypes.filter(
        (subType) => project.subType == subType.code
    );
    if (foundedSubTypes.length <= 0) {
        return false;
    }
    const foundedSubType = foundedSubTypes[0];
    return stringIncludes(foundedSubType.text, searchTerm);
};

export const filterProjectBySubSubType = (
    project: ProjectResponse,
    searchTerm: string,
    subSubTypes: SubSubType[]
): boolean => {
    const foundedSubSubTypes = subSubTypes.filter(
        (subSubType) => project.subSubType == subSubType.code
    );
    if (foundedSubSubTypes.length <= 0) {
        return false;
    }
    const foundedSubSubType = foundedSubSubTypes[0];
    return stringIncludes(foundedSubSubType.text, searchTerm);
};

export const filterProjectByTeam = (
    { team }: ProjectResponse,
    searchTerm: string
): boolean => {
    if (team == null) {
        return true;
    }
    return Object.values(team).some((teamName) =>
        stringIncludes(teamName, searchTerm)
    );
};

export const filterProjectByTeamKeyAppers = (
    project: ProjectResponse,
    searchTerm: string,
    teamTranslations: Record<string, string>
): boolean => {
    if (!project.team) {
        return false;
    }
    return (Object.keys(teamTranslations) as (keyof Team)[]).some(
        (key) =>
            !!(project.team && project.team[key as keyof Team]) &&
            stringIncludes(teamTranslations[key], searchTerm)
    );
};

export const filterProjectByGender = (
    project: ProjectResponse,
    searchTerm: string,
    genders: Gender[]
): boolean => {
    return (project?.genders ?? []).some((projectGender) => {
        const foundedGeners = genders.filter(
            (gender) => projectGender.code == gender.code
        );
        if (foundedGeners.length <= 0) {
            return false;
        }
        const foundedGender = foundedGeners[0];
        return stringIncludes(foundedGender.text, searchTerm);
    });
};
