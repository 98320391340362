import React, { useState } from 'react';
import { Award } from 'core/models/Award';
import { IconBootstrap } from 'components/shared/Icon';
import clsx from 'clsx';
import { Modal } from 'components/shared/modal/Modal';

type Props = {
    awards: Award[];
};

export const ProjectAwardsV2 = ({ awards }: Props) => {
    const [selectedAward, setSelectedAward] = useState<Award>();
    return (
        <>
            <ProjectAwardsV2CardModal
                show={selectedAward !== undefined}
                image={selectedAward?.image ?? ''}
                onClose={() => setSelectedAward(undefined)}
            />
            <div className="col-12 col-md-2 pt-3 d-flex flex-row flex-md-column align-items-end">
                {awards?.map((award) => (
                    <ProjectAwardsV2Card
                        key={award.id}
                        award={award}
                        onClick={() => setSelectedAward(award)}
                    />
                ))}
            </div>
        </>
    );
};

type ProjectAwardsV2Card = {
    award: Award;
    onClick: () => void;
};

const ProjectAwardsV2Card = ({
    award: { image, size, displayModal },
    onClick,
}: ProjectAwardsV2Card) => {
    const [over, setOver] = useState(false);

    const onMouseOver = () => setOver(true);
    const onMouseOut = () => setOver(false);

    return (
        <div className="position-relative">
            <img
                src={image}
                className="img-fluid m-2"
                style={{
                    width: size === 'big' ? '92px' : '92px',
                    opacity: over ? 0.5 : 1,
                }}
            />
            {displayModal && (
                <div
                    className={clsx(
                        'card-img-overlay position-absolute d-flex flex-column justify-content-start align-items-end',
                        {
                            pointer: displayModal,
                        }
                    )}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onClick={onClick}
                >
                    <IconBootstrap name="eye" color="secondary" />
                </div>
            )}
        </div>
    );
};

type ProjectAwardsV2CardModalProps = {
    show: boolean;
    image: string;
    onClose: () => void;
};

const ProjectAwardsV2CardModal = ({
    show,
    image,
    onClose,
}: ProjectAwardsV2CardModalProps) => {
    return (
        <Modal.Root show={show}>
            <Modal.Header title="" onClose={onClose} />
            <Modal.Body>
                <img src={image} className="img-fluid m-2" />
            </Modal.Body>
        </Modal.Root>
    );
};
