import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form/Input';
import { useNavigate, useParams } from 'react-router-dom';
import {
    append,
    save,
} from '../../../core/services/firebase/resources.service';
import { Row } from '../../shared/Row';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK } from '../../../core/constants/routes';
import { OtherWorkResponse } from '../../../core/models/OtherWork';
import { TextArea } from '../../shared/form/TextArea';
import { Checkbox } from '../../shared/form/Checkbox';
import { Col } from '../../shared/Col';
import { SaveButton } from '../../shared/button/SaveButton';

type Props = {
    otherWork?: OtherWorkResponse;
};

export const OtherWorkDetailForm = ({ otherWork }: Props) => {
    const T_RESOURCE = 'pages.back.otherWorks.detail';

    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const methods = useForm({
        defaultValues: otherWork,
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data: OtherWorkResponse) => {
        const handledData = {
            ...data,
            position: isNaN(data.position) ? 0 : data.position,
        };
        if (otherWork) {
            save(RESOURCES.OTHER_WORKS, id, handledData);
        } else {
            append(RESOURCES.OTHER_WORKS, handledData);
        }
        navigate(BACK.OTHER_WORKS);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButton floating submit />
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <Input
                                name={`title.${language}`}
                                label={t(`${T_RESOURCE}.fields.title`, {
                                    language,
                                })}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <TextArea
                                name={`description.${language}`}
                                label={t(`${T_RESOURCE}.fields.description`, {
                                    language,
                                })}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <Input
                                name={`rol.${language}`}
                                label={t(`${T_RESOURCE}.fields.rol`, {
                                    language,
                                })}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-4">
                        <Input
                            name="year"
                            label={t(`${T_RESOURCE}.fields.year`)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Input
                            type="number"
                            name="position"
                            label={t(`${T_RESOURCE}.fields.position`)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Checkbox
                            name="visible"
                            label={t(`${T_RESOURCE}.fields.visible`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1}>
                        <Input
                            name="link"
                            label={t(`${T_RESOURCE}.fields.link`)}
                        />
                    </Col>
                </Row>
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <Input
                                name={`direction.${language}`}
                                label={t(`${T_RESOURCE}.fields.direction`, {
                                    language,
                                })}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="productor.name"
                            label={t(`${T_RESOURCE}.fields.productor.name`)}
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="productor.link"
                            label={t(`${T_RESOURCE}.fields.productor.link`)}
                        />
                    </Col>
                </Row>
            </form>
        </FormProvider>
    );
};
