export const ROOT = '/';

export const LOGIN = '/login';
export const LOGOUT = '/logout';

export const ABOUT = '/about';
export const CONTACT = '/contact';
export const OTHER_WORK = '/other-work';

const PROJECTS = '/projects';

export const PROJECT_DETAIL = (id: string) => `${PROJECTS}/${id}`;

export const BACK_ROOT = '/back';

export const BACK = {
    PROJECTS: `${BACK_ROOT}/projects`,
    PROJECT_CREATE: `${BACK_ROOT}/projects/create`,
    PROJECT_DETAIL: (id: string) => `${BACK.PROJECTS}/${id}`,
    TYPES: `${BACK_ROOT}/types`,
    TYPES_CREATE: `${BACK_ROOT}/types/create`,
    TYPE_DETAIL: (id: string) => `${BACK.TYPES}/${id}`,
    LANGUAGES: `${BACK_ROOT}/languages`,
    LANGUAGE_DETAIL: (id: string) => `${BACK.LANGUAGES}/${id}`,
    GENDERS: `${BACK_ROOT}/genders`,
    GENDER_CREATE: `${BACK_ROOT}/genders/create`,
    GENDER_DETAIL: (id: string) => `${BACK.GENDERS}/${id}`,
    SUBTYPES: `${BACK_ROOT}/subtypes`,
    SUBTYPE_CREATE: `${BACK_ROOT}/subtypes/create`,
    SUBTYPE_DETAIL: (id: string) => `${BACK.SUBTYPES}/${id}`,
    SUBSUBTYPES: `${BACK_ROOT}/subsubtypes`,
    SUBSUBTYPE_CREATE: `${BACK_ROOT}/subsubtypes/create`,
    SUBSUBTYPE_DETAIL: (id: string) => `${BACK.SUBSUBTYPES}/${id}`,
    GENERAL: `${BACK_ROOT}/general`,
    TEST_A: `${BACK_ROOT}/test-a`,
    TEST_B: `${BACK_ROOT}/test-b`,
    OTHER_WORKS: `${BACK_ROOT}/other-works`,
    OTHER_WORK_CREATE: `${BACK_ROOT}/other-works/create`,
    OTHER_WORK_DETAIL: (id: string) => `${BACK.OTHER_WORKS}/${id}`,
    AWARDS: `${BACK_ROOT}/awards`,
    AWARD_CREATE: `${BACK_ROOT}/awards/create`,
    AWARD_DETAIL: (id: string) => `${BACK.AWARDS}/${id}`,
    MENU: `${BACK_ROOT}/menu`,
    MENU_CREATE: `${BACK_ROOT}/menu/create`,
    MENU_DETAIL: (id: string) => `${BACK.MENU}/${id}`,
    SUB_MENU: (id: string) => `${BACK_ROOT}/submenu/${id}`,
    SUB_MENU_CREATE: (id: string) => `${BACK_ROOT}/submenu/${id}/create`,
    SUB_MENU_DETAIL: (menuId: string, id: string) => `${BACK_ROOT}/submenu/${menuId}/${id}`,
};
