import { SubType, SubTypeResponse } from '../models/SubType';
import { TranslatedString } from '../models/TranslatedString';

/* prettier-ignore */
export const subTypeMapper = (locale: string) => (subType: SubTypeResponse): SubType => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        id: subType?.id ?? '',
        code: subType?.code ?? '',
        text: subType?.text?.[translatedStringLocale] ?? '',
    };
};

export const convertSubTypeToSelectOption = (
    subType: SubType
): { key: string; name: string } => ({ key: subType.code, name: subType.text });
