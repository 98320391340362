import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { MarginType, PaddingType } from './type/BootstrapType';

type Props = {
    children: ReactNode;
    className?: string;
    m?: MarginType;
    p?: PaddingType;
};

const generateProps = ({
    className,
    m = 0,
    p = 0,
}: Omit<Props, 'children'>) => ({
    className: clsx(className, `m-${m}`, `p-${p}`),
});

export const H1 = ({ children, ...props }: Props) => {
    return <h1 {...generateProps(props)}>{children}</h1>;
};

export const H2 = ({ children, ...props }: Props) => {
    return <h2 {...generateProps(props)}>{children}</h2>;
};

export const H3 = ({ children, ...props }: Props) => {
    return <h3 {...generateProps(props)}>{children}</h3>;
};

export const H4 = ({ children, ...props }: Props) => {
    return <h4 {...generateProps(props)}>{children}</h4>;
};

export const H5 = ({ children, ...props }: Props) => {
    return <h5 {...generateProps(props)}>{children}</h5>;
};
