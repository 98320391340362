/* eslint-disable @typescript-eslint/no-explicit-any */

export const push = <T>(arr: T[], value: T): boolean => {
    if (arr === null || arr === undefined) {
        return false;
    }
    arr.push(value);
    return true;
};

export const remove = <T>(arr: T[], value: T): boolean => {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
        return true;
    }
    return false;
};

export const arrayOf = (to: number) =>
    Array.from({ length: to }, (_, index) => index + 1);

export const divideByFn = <T>(
    arr: T[],
    fns: ((...args: any[]) => boolean)[]
): T[][] => fns.map((fn) => arr.filter(fn));
