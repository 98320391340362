import { useEffect, useState } from 'react';
import { getOneByHash } from 'core/services/firebase/resources.service';

type Props<T, R> = {
    resource: string;
    hash: string | undefined;
    mapper?: (obj: R) => T;
};

export const useGetOneByHash = <T, R>({
    resource,
    hash,
    mapper,
}: Props<T, R>) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<T | null | undefined>(null);

    const mutation = async () => {
        setLoading(true);
        const response = await getOneByHash(resource, hash);
        if (mapper) {
            setData(mapper(response as R));
        } else {
            setData(response as T);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (hash) {
            mutation();
        }
    }, [resource, hash]);

    useEffect(() => {
        setError(data == undefined && data != null);
    }, [data]);

    return { data, loading, mutation, error };
};
