import React, { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import { ColorTypeSmall, MarginType, PaddingType } from './type/BootstrapType';

type Props = {
    children?: ReactNode;
    color?: ColorTypeSmall;
    rounded?: boolean;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
    m?: MarginType;
    p?: PaddingType;
};

export const Badge = ({
    children,
    color = 'primary',
    rounded = false,
    className,
    style,
    onClick,
    m = 0,
    p = 1,
}: Props) => {
    return (
        <span
            className={clsx(
                'badge',
                `text-bg-${color}`,
                `m-${m}`,
                `p-${p}`,
                className,
                {
                    'rounded-pill': rounded,
                    pointer: onClick,
                }
            )}
            style={style}
            onClick={onClick}
        >
            {children}
        </span>
    );
};
