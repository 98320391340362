import { Award } from 'core/models/Award';
import { Project, ProjectResponse } from '../models/Project';
import { SubSubType } from '../models/SubSubType';
import { SubType } from '../models/SubType';
import { TranslatedString } from '../models/TranslatedString';
import { Type } from '../models/Type';
import { genderMapper } from './genderMapper';
import { projectGalleryMapper } from './projectGalleryMapper';
import { teamMapper } from './teamMapper';

/* prettier-ignore */
export const projectMapper = (locale: string) => (types: Type[], subTypes: SubType[], subSubTypes: SubSubType[], awards?: Award[]) => (project: ProjectResponse): Project => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        id: project?.id ?? '',
        hash: project?.hash ?? '',
        title: project?.title?.[translatedStringLocale] ?? '',
        description:
            project?.description?.[translatedStringLocale] ?? '',
        type: findByCode(types, project?.type),
        subType: findByCode(subTypes, project?.subType),
        subSubType: findByCode(subSubTypes, project?.subSubType),
        image: project?.image ?? '',
        year: !project?.year || project?.year <= 0 ? null : project?.year,
        vimeoVideo: project?.vimeoVideo ?? '',
        youTubeVideo: project?.youTubeVideo ?? '',
        team: project?.team ? teamMapper(project.team) : null,
        position: project?.position ?? 0,
        visible: project?.visible ?? false,
        duration: project?.duration ?? 0,
        gallery: project?.gallery?.map(projectGalleryMapper),
        genders: project?.genders?.map(genderMapper(locale)),
        awards: awards ? findByCodes(awards, project?.awards) : []
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findByCode = (arr: any[], value: string) =>
    arr.find((a) => a.code === value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findByCodes = (arr: any[], values: string[]) =>
    arr?.filter((a) => values?.includes(a.id));
