import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BACK, ROOT } from '../../../core/constants/routes';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../firebase';
import { LogoutButton } from 'components/shared/button/LogoutButton';

type MenuItemProps = {
    href?: string;
    text: string;
    children?: ReactNode;
    hasSubItems?: boolean;
};

const MenuItem = ({ href = '', text, children, hasSubItems }: MenuItemProps) => {
    const isPath = (path: string) => location.pathname.startsWith(path);

    return (
        <li className={clsx('nav-item', { dropdown: hasSubItems })}>
            <a
                href={hasSubItems ? '#' : href}
                className={clsx('nav-link', {
                    active: isPath(href),
                    'dropdown-toggle': hasSubItems,
                })}
                {...(hasSubItems ? { 'data-bs-toggle': 'dropdown' } : {})}
            >
                {text}
            </a>
            {hasSubItems && <ul className="dropdown-menu">{children}</ul>}
        </li>
    );
};

const MenuSubItem = ({
    href,
    text,
}: Omit<MenuItemProps, 'hasSubItems' | 'children'>) => {
    return (
        <li>
            <a className="dropdown-item" href={href}>
                {text}
            </a>
        </li>
    );
};

export const Menu = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showMenu, setShowMenu] = useState(false);

    return (
        <nav className="navbar navbar-expand-md">
            <div className="container-fluid p-0">
                <span
                    className="navbar-brand"
                    onClick={() => navigate(ROOT)}
                    role="button"
                >
                    GALA
                </span>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setShowMenu(!showMenu)}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className={clsx('collapse navbar-collapse', {
                        show: showMenu,
                    })}
                >
                    <ul className="navbar-nav me-auto mb-lg-0">
                        <MenuItem
                            href={BACK.PROJECTS}
                            text={t('back.menu.projects')}
                        />
                        <MenuItem
                            href={BACK.OTHER_WORKS}
                            text={t('back.menu.otherWorks')}
                        />
                        <MenuItem
                            href={BACK.AWARDS}
                            text={t('back.menu.awards')}
                        />
                        <MenuItem text="T/ST/SST" hasSubItems>
                            <MenuSubItem
                                href={BACK.TYPES}
                                text={t('back.menu.types')}
                            />
                            <MenuSubItem
                                href={BACK.SUBTYPES}
                                text={t('back.menu.subtypes')}
                            />
                            <MenuSubItem
                                href={BACK.SUBSUBTYPES}
                                text={t('back.menu.subsubtypes')}
                            />
                        </MenuItem>
                        <MenuItem
                            href={BACK.GENERAL}
                            text={t('back.menu.general')}
                        />
                        <MenuItem
                            href={BACK.GENDERS}
                            text={t('back.menu.genders')}
                        />
                        <MenuItem
                            href={BACK.MENU}
                            text={t('back.menu.menu')}
                        />
                        <MenuItem
                            href={BACK.LANGUAGES}
                            text={t('back.menu.languages')}
                        />
                        <MenuItem href={BACK.TEST_A} text={t('back.menu.test-a')} />
                        <MenuItem href={BACK.TEST_B} text={t('back.menu.test-b')} />
                    </ul>
                    <LogoutButton
                        onLogout={() => {
                            logout();
                            navigate(ROOT);
                        }}
                    />
                </div>
            </div>
        </nav>
    );
};
