import React from 'react';
import clsx from 'clsx';
import { useGetOne } from 'core/hooks/firebase/useGetOne';
import { RESOURCES } from 'core/constants/resources';

type Props = {
    title: string;
    size: number;
};

export const ProjectTitle = ({ title, size }: Props) => {
    const { data: fontFamilySecondary } = useGetOne({
        resource: RESOURCES.GENERAL,
        id: 'fontFamilySecondary',
    });

    return (
        <h2
            className={clsx('my-2', `font-${fontFamilySecondary}`)}
            style={{ fontSize: size }}
        >
            {title}
        </h2>
    );
};
