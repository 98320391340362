import { Gender, GenderResponse } from '../models/Gender';
import { TranslatedString } from '../models/TranslatedString';

// prettier-ignore
export const genderMapper = (locale: string) => (gender: GenderResponse): Gender => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        id: gender?.id ?? '',
        code: gender?.code ?? '',
        text: gender?.text?.[translatedStringLocale] ?? '',
    };
};

export const convertGenderToSelectOption = (
    gender: Gender
): { key: string; name: string } => ({ key: gender.code, name: gender.text });
