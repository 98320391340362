import React from 'react';
import clsx from 'clsx';
import { Button } from './Button';
import { IconBootstrap } from '../Icon';
import { MarginType, PaddingType } from '../type/BootstrapType';

type Props = {
    children?: string;
    className?: string;
    disabled?: boolean;
    floating?: boolean;
    onClick?: () => void;
    m?: MarginType;
    p?: PaddingType;
};

export const AddButton = ({
    children,
    className,
    disabled = false,
    floating = false,
    onClick,
    m = 2,
    p = 1,
}: Props) => {
    return (
        <Button
            className={clsx(`m-${m}`, `p-${p}`, className, {
                'position-fixed bottom-0 end-0 z-1': floating,
            })}
            disabled={disabled}
            onClick={onClick}
        >
            {children ? (
                <>{children}</>
            ) : (
                <IconBootstrap name="plus" color="white" size="xl" />
            )}
        </Button>
    );
};
