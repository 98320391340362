import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getByPath } from '../../../core/utils/object.util';

type Props = {
    name: string;
};

export const ErrorLabel = ({ name }: Props) => {
    const methods = useFormContext();
    const errors = methods?.formState?.errors;
    const hasError = !!getByPath(errors, name);
    const message = getByPath(errors, name)?.message ?? '';

    if (!hasError) {
        return <></>;
    }

    return <div className="form-text text-danger">{message}</div>;
};
