import React from 'react';
import { BackLayout } from '../../../components/layouts/back/BackLayout';
import { OtherWorkDetailForm } from '../../../components/back/form/OtherWorkDetailForm';

export const OtherWorkCreate = () => {
    return (
        <BackLayout>
            <OtherWorkDetailForm />
        </BackLayout>
    );
};
