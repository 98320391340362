import {
    Menu,
    MenuResponse,
    MenuSubItem,
    MenuSubItemResponse,
} from '../models/Menu';
import { TranslatedString } from '../models/TranslatedString';

/* prettier-ignore */
export const menuMapper = (locale: string) => (menu: MenuResponse): Menu => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        id: menu?.id ?? '',
        title: menu?.title?.[translatedStringLocale] ?? '',
        url: menu?.url ?? '',
        subItems: menu?.subItems
            ? Object.values(menu?.subItems).map(menuItemMapper(locale))
            : [],
        position: menu?.position ?? 0,
        visible: menu?.visible ?? false,
    };
};

/* prettier-ignore */
const menuItemMapper = (locale: string) => (subItem: MenuSubItemResponse): MenuSubItem => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        id: subItem?.id ?? '',
        title: subItem?.title?.[translatedStringLocale] ?? '',
        url: subItem?.url ?? '',
        position: subItem?.position ?? 0,
        visible: subItem?.visible ?? false,
    };
};
