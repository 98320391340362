import { Contact, ContactResponse } from '../models/Contact';

// prettier-ignore
export const contactMapper = (contact: ContactResponse): Contact => {
    return {
        name: contact?.name ??'',
        email: contact?.email ?? '',
        phone: contact?.phone ?? '',
        instagram: contact?.instagram ?? '',
        imdb: contact?.imdb ?? '',
        linkedin: contact?.linkedin ?? '',
        image: contact?.image ?? '',
    };
};
