import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form/Input';
import { useNavigate, useParams } from 'react-router-dom';
import {
    append,
    save,
} from '../../../core/services/firebase/resources.service';
import { Row } from '../../shared/Row';
import { RESOURCES } from '../../../core/constants/resources';
import { BACK } from '../../../core/constants/routes';
import { Col } from '../../shared/Col';
import { SaveButton } from '../../shared/button/SaveButton';
import { MenuSubItemResponse } from 'core/models/Menu';
import { Checkbox } from 'components/shared/form/Checkbox';

type Props = {
    subItem?: MenuSubItemResponse;
};

export const SubMenuDetailForm = ({ subItem }: Props) => {
    const T_RESOURCE = 'pages.back.submenus.detail';

    const { t, i18n } = useTranslation();
    const { id: menuId, subItemId } = useParams();
    const navigate = useNavigate();

    const methods = useForm({
        defaultValues: subItem,
    });

    const { handleSubmit } = methods;

    const onSubmit = async (data: MenuSubItemResponse) => {
        const resource = `${RESOURCES.MENU}/${menuId}/subItems`;
        if (subItem) {
            save(resource, subItemId, data);
        } else {
            append(resource, data);
        }
        menuId && navigate(BACK.SUB_MENU(menuId));
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SaveButton floating submit />
                <Row>
                    {i18n.languages.map((language) => (
                        <Col key={language} px={1} className="col-12 col-md-4">
                            <Input
                                name={`title.${language}`}
                                label={t(`${T_RESOURCE}.fields.title`, {
                                    language,
                                })}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            name="url"
                            label={t(`${T_RESOURCE}.fields.url`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col px={1} className="col-12 col-md-6">
                        <Input
                            type="number"
                            name="position"
                            label={t(`${T_RESOURCE}.fields.position`)}
                            required
                        />
                    </Col>
                    <Col px={1} className="col-12 col-md-4">
                        <Checkbox
                            name="visible"
                            label={t(`${T_RESOURCE}.fields.visible`)}
                        />
                    </Col>
                </Row>
            </form>
        </FormProvider>
    );
};
