import { About, AboutResponse } from '../models/About';
import { TranslatedString } from '../models/TranslatedString';

// prettier-ignore
export const aboutMapper = (locale: string) => (about: AboutResponse): About => {
    const translatedStringLocale = locale as keyof TranslatedString;
    return {
        content: about?.content?.[translatedStringLocale] ?? '',
        image: about?.image ?? ''
    };
};
