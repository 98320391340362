import React from 'react';
import { Navigate } from 'react-router-dom';
import { logout } from '../../firebase';

type Props = {
    navigateTo: string;
};

export const Logout = ({ navigateTo }: Props) => {
    logout();
    return <Navigate to={navigateTo} />;
};
